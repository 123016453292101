// React
import React, { useEffect, useState } from "react";
// Third party
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
// OpenAPI
import { apiConfig } from "../components/ConfigurationApi/Configuration";
import { expiredTokenValidation } from "../api/expiredTokenValidation";
import {
  MultiStepFormDomainApi,
  NoteSourceTypeDomainApi,
} from "../openapi";
import { getApiData } from "../controller/getApiDataConfiguration";
// Components
import { callFetchAllList, fetchMnaData } from "../components/MnaForm/helpers";
import Loader from "../components/Loader";
import MnaForm, { Steps } from "../components/MnaForm";
// Helpers
import {
  processArrayData,
  processArrayDataGeneral,
} from "../helpers/processArrayData";
import { structureFormData } from "../helpers/structureFormData";
import { initialValues } from "../components/MnaForm/data/initialValues";
// Images
import Keyboard from "../assets/svg/keyboard2.svg";
import Minus from "../assets/svg/minus.svg";
import Plus from "../assets/svg/pink-plus.svg";
// Types
import { HandleMenuProps } from "../interfaces/pages/variedInterfaces";

function MnaAddEdit({ isMenuOpen, setIsMenuOpen }: HandleMenuProps) {
  const [serverError, setServerError] = useState<any>(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const [mnaCategoryList, setMnaCategoryList] = useState<any>([]);
  const [mnaSubCategoryList, setMnaSubCategoryList] = useState<any>([]);
  const [linkCategoryList, setLinkCategoryList] = useState<any>([]);
  const [txDocSummariesList, setTxDocSummariesList] = useState<any>([]);
  const [conditionCategoryList, setConditionCategoryList] = useState<any>([]);
  const [conditionStatusList, setConditionStatusList] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [changedValues, setChangedValues] = useState<any>({});
  const [featuresLoading, setFeaturesLoading] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<Steps>(0);
  const [formId, setFormId] = useState<string | number | undefined>();
  const [errorsByPage, setErrorsByPage] = useState({ [activeStep]: {} });
  let formIdResponse: string | number | undefined;
  const [deleteID, setDeleteID] = useState<number>();
  const [deleteModal, setDeleteModal] = useState(false);
  const [association, setAssociation] = useState("");
  const [sourceType, setSourceType] = useState<any>([]);
  const [sectorList, setSectorList] = useState<any>([]);
  const [editorData, setEditorData] = useState({ ref: null, keyName: "" });

  // Fetch note source type options on component mount
  useEffect(() => {
    fetchAllList();
    fetchNoteSourceType();
    fetchSectorList();
  }, []);

  // Function to fetch note source type options
  const fetchAllList = async () => {
    callFetchAllList({
      setConditionCategoryList,
      setConditionStatusList,
      setLinkCategoryList,
      setLoading,
      setMnaCategoryList,
      setMnaSubCategoryList,
      setTxDocSummariesList,
    });
  };

  // Function to fetch Sector List options
  const fetchSectorList = async () => {
    try {
      const api = new getApiData();
      const response = await api.getSectorDomainApi(id);
      const filteredOptions = response?.data?.content!.map((item: any) => ({
        value: item.id,
        label: item.name,
      }));
      setSectorList(filteredOptions);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchNoteSourceType = async () => {
    try {
      const api = new NoteSourceTypeDomainApi(apiConfig());
      const response = await api.getNoteSourceType();
      setSourceType(response.data.content);
    } catch (error) {
      expiredTokenValidation(error);
    }
  };

  const fetchFeatures = async (
    inputValue?: string
  ): Promise<
    | {
        value: any;
        label: any;
      }[]
    | []
  > => {
    // Simulate an asynchronous call with a delay
    setFeaturesLoading(true);
    try {
      const api = new getApiData();
      setFeaturesLoading(false);
      return api.getFeatureDomainApi();
    } catch (error) {
      console.error("Error fetching options:", error);
      setFeaturesLoading(false);
      expiredTokenValidation(error);

      return [];
    }
  };

  useEffect(() => {
    if (!id) return;
    fetchMnaData({
      fetchFeatures,
      formik,
      id,
      setChangedValues,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  async function onSubmitForm() {
    let data: any = { ...formik.values, version: 0 };
    data["dateAnnounced"] =
      data["dateAnnounced"] !== null
        ? new Date(data.dateAnnounced).toISOString()
        : null;
    if (data.whatMatters.length > 0) {
      // CK Editor Start
      const keys: any = editorData.keyName.match(/([^[.\]])+/g);
      if (keys) {
        if (!id) {
          if (!data.whatMatters) data.whatMatters = []; 
          if (!data.whatMatters[keys[1]]) data.whatMatters[keys[1]] = {}; 
        
          data.whatMatters[keys[1]].note = editorData.ref;
        } else {
          let shallowCopy = JSON.parse(JSON.stringify(data));
        
          if (!shallowCopy.whatMatters) shallowCopy.whatMatters = []; 
          if (!shallowCopy.whatMatters[keys[1]]) shallowCopy.whatMatters[keys[1]] = {}; 
        
          shallowCopy.whatMatters[keys[1]].note = editorData.ref;
          data.whatMatters = shallowCopy.whatMatters;
        }
      }
      // CK Editor End

      const whatMatters = processArrayDataGeneral(
        data,
        changedValues,
        "whatMatters",
        id
      );
      if (id) {
        data.whatMatters = whatMatters;
      } else {
        delete whatMatters.edit;
        delete whatMatters.delete;
        data.whatMatters = whatMatters?.add?.length > 0 ? whatMatters : null;
      }
    } else {
      const whatMatters: any = { add: null, delete: [], edit: null };
      whatMatters.delete = changedValues.whatMatters?.map(
        (item: any) => item.id
      );
      data.whatMatters = id ? whatMatters : null;
    }

    if (data.links.length > 0) {
      const links = processArrayDataGeneral(data, changedValues, "links", id);

      if (id) {
        data.links = links;
      } else {
        delete links.edit;
        delete links.delete;
        data.links = links?.add?.length > 0 ? links : null;
      }
    } else {
      const links: any = { add: null, delete: [], edit: null };
      links.delete = changedValues.links?.map((item: any) => item.id);
      data.links = id ? links : null;
    }

    if (data.features.length > 0) {
      const features = processArrayData(data, changedValues, "features", id);
      if (id) {
        data.features = features;
      } else {
        delete features.edit;
        delete features.delete;
        data.features = features?.add?.length > 0 ? features : null;
      }
    } else {
      const features: any = { add: null, delete: [], edit: null };
      features.delete = changedValues.features?.map((item: any) => item.id);
      data.features = id ? features : null;
    }

    if (data.conditions.length > 0) {
      const conditions = processArrayDataGeneral(
        data,
        changedValues,
        "conditions",
        id
      );

      if (id) {
        if (conditions?.edit) {
          Object.keys(conditions.edit).forEach((condition) => {
            const expectedConfirmed =
              conditions?.edit[condition].expectedConfirmed;
            const filed = conditions?.edit[condition].filed;
            conditions.edit[condition].expectedConfirmed = expectedConfirmed
              ? new Date(expectedConfirmed).toISOString()
              : null;
            conditions.edit[condition].filed = filed
              ? new Date(filed).toISOString()
              : null;
          });
        }
        if (conditions?.add) {
          Object.keys(conditions.add).forEach((condition) => {
            const expectedConfirmed =
              conditions?.add[condition].expectedConfirmed;
            const filed = conditions?.add[condition].filed;
            conditions.add[condition].expectedConfirmed = expectedConfirmed
              ? new Date(expectedConfirmed).toISOString()
              : null;
            conditions.add[condition].filed = filed
              ? new Date(filed).toISOString()
              : null;
          });
        }
        data.conditions = conditions;
      } else {
        delete conditions.edit;
        delete conditions.delete;
        if (conditions?.add) {
          Object.keys(conditions.add).forEach((condition) => {
            const expectedConfirmed =
              conditions?.add[condition].expectedConfirmed;
            const filed = conditions?.add[condition].filed;
            conditions.add[condition].expectedConfirmed = expectedConfirmed
              ? new Date(expectedConfirmed).toISOString()
              : null;
            conditions.add[condition].filed = filed
              ? new Date(filed).toISOString()
              : null;
          });
        }
        data.conditions = conditions?.add?.length > 0 ? conditions : null;
      }
    } else {
      const conditions: any = { add: null, delete: [], edit: null };
      conditions.delete = changedValues.conditions?.map((item: any) => item.id);
      data.conditions = id ? conditions : null;
    }

    if (data.brokerClosingDates && data.financingDates && data.outDates) {
      const formatDates = (arr: any) => {
        return arr.map((item: any) => {
          if (item.dateEvent) {
            item.dateEvent = new Date(item.dateEvent).toISOString();
          }
          return item;
        });
      };

      data.brokerClosingDates = formatDates(data.brokerClosingDates);
      data.financingDates = formatDates(data.financingDates);
      data.outDates = formatDates(data.outDates);
    }

    if (data.outDates.length > 0) {
      const outDates = processArrayDataGeneral(
        data,
        changedValues,
        "outDates",
        id
      );

      if (id) {
        data.outDates = outDates;
      } else {
        delete outDates.edit;
        delete outDates.delete;
        data.outDates = outDates?.add?.length > 0 ? outDates : null;
      }
    } else {
      const outDates: any = { add: null, delete: [], edit: null };
      outDates.delete = changedValues.outDates?.map((item: any) => item.id);
      data.outDates = id ? outDates : null;
    }

    if (data.brokerClosingDates.length > 0) {
      const brokerClosingDates = processArrayDataGeneral(
        data,
        changedValues,
        "brokerClosingDates",
        id
      );

      if (id) {
        data.brokerClosingDates = brokerClosingDates;
      } else {
        delete brokerClosingDates.edit;
        delete brokerClosingDates.delete;
        data.brokerClosingDates =
          brokerClosingDates?.add?.length > 0 ? brokerClosingDates : null;
      }
    } else {
      const brokerClosingDates: any = { add: null, delete: [], edit: null };
      brokerClosingDates.delete = changedValues.brokerClosingDates?.map(
        (item: any) => item.id
      );
      data.brokerClosingDates = id ? brokerClosingDates : null;
    }

    if (data.financingDates.length > 0) {
      const financingDates = processArrayDataGeneral(
        data,
        changedValues,
        "financingDates",
        id
      );

      if (id) {
        data.financingDates = financingDates;
      } else {
        delete financingDates.edit;
        delete financingDates.delete;
        data.financingDates =
          financingDates?.add?.length > 0 ? financingDates : null;
      }
    } else {
      const financingDates: any = { add: null, delete: [], edit: null };
      financingDates.delete = changedValues.financingDates?.map(
        (item: any) => item.id
      );
      data.financingDates = id ? financingDates : null;
    }

    if (data.tags?.length > 0) {
      const tags = data.tags.reduce(
        (result: any, obj: any) => {
          if (!changedValues.tags?.includes(obj)) {
            result.add = result.add || [];
            result.add = [...result.add, { tag: obj.label }];
          }
          return result;
        },
        { add: null, delete: null }
      );

      changedValues.tags?.forEach((item: any) => {
        const deletedObj = data.tags?.map((obj: any) => {
          if (obj?.value === item.value) {
            return obj.value;
          } else {
            return null;
          }
        });
        if (!deletedObj.includes(item.value)) {
          tags.delete = tags.delete || [];
          tags.delete = [...tags.delete, item.value];
        }
      });
      if (id) {
        data.tags = tags;
      } else {
        delete tags.delete;
        data.tags = tags?.add?.length > 0 ? tags : null;
      }
    } else {
      const tags: any = { add: null, delete: [] };
      tags.delete = changedValues.tags?.map((item: any) => item.value);
      data.tags = id ? tags : null;
    }

    if (data.txDocSummaries.length > 0) {
      const txDocSummaries = processArrayDataGeneral(
        data,
        changedValues,
        "txDocSummaries",
        id
      );
      if (id) {
        data.txDocSummaries = txDocSummaries;
      } else {
        delete txDocSummaries.edit;
        delete txDocSummaries.delete;
        data.txDocSummaries =
          txDocSummaries?.add?.length > 0 ? txDocSummaries : null;
      }
    } else {
      const txDocSummaries: any = { add: null, delete: [], edit: null };
      txDocSummaries.delete = changedValues.txDocSummaries?.map(
        (item: any) => item.id
      );
      data.txDocSummaries = id ? txDocSummaries : null;
    }

    if (
      Object.keys(data.files).includes("add") ||
      Object.keys(data.files).includes("delete")
    ) {
    } else {
      data.files = { add: null, delete: null };
    }

    if (data?.acquirer?.value) {
      data.acquirer = data.acquirer.label;
    }
    if (data?.target?.value) {
      data.target = data.target.label;
    }

    try {
      // API call

      const formDataForActiveStep = structureFormData(data, activeStep);
      const api = new MultiStepFormDomainApi(apiConfig());
      let body = formId
        ? { formId, formJson: formDataForActiveStep.data }
        : { formJson: formDataForActiveStep.data };
      if (id) {
        const response = await api.updateMultiStepFormGetObject(
          "mnas",
          String(id),
          activeStep + 1,
          body
        );
        formIdResponse = response?.data?.formId;
        setFormId(response?.data?.formId);
      } else {
        const response = await api.createMultiStepFormGetObject(
          "mnas",
          activeStep + 1,
          body
        );
        const formId = response.data.formId;
        formIdResponse = formId;
        setFormId(formId);
      }
      // setActiveStep((prev) => (prev + 1) as Steps);
      setErrorsByPage((prev) => {
        return { ...prev, [activeStep]: {} };
      });

      return true;
    } catch (error: any) {
      if (error.response) {
        if (
          error.response.data?.exception &&
          !error.response.data?.fieldErrors?.length
        ) {
          // toast.custom((t) => <CustomErrorModal visible={t.visible} error={error} />, {
          //   duration: 500,
          // });
        }
        if (error.response.data?.fieldErrors?.length) {
          const errorObject: any = error.response.data?.fieldErrors.reduce(
            (accumulator1: any, error: any) => {
              if (error.field.includes("[")) {
                const parts = error.field.split(/\.|\[|\]/);
                const name = parts[0];
                const index = parts[2];
                const keyName = parts[4];

                const fieldName = formik.values[name];
                const obj = fieldName.findIndex(
                  (item: any) => item.id === Number(index)
                );

                if (obj !== -1) {
                  const value = formik.values[name][obj][keyName];

                  if (value === "" || value === null) {
                    accumulator1 = {
                      ...accumulator1,
                      [`${name}[${obj}].${keyName}`]: error.defaultMsg,
                    };
                  }
                } else {
                  const indicesWithoutId = fieldName
                    .map((obj: any, index: any) =>
                      obj.hasOwnProperty("id") ? -1 : index
                    )
                    .filter((index: any) => index !== -1);

                  indicesWithoutId.forEach((item: any) => {
                    const obj = formik.values[name][item];
                    const isValuesEmpty = Object.keys(obj).every(
                      (key) =>
                        key === "mnaId" || obj[key] === "" || obj[key] === null
                    );

                    if (
                      !isValuesEmpty &&
                      (obj[keyName] === "" || obj[keyName] === null)
                    ) {
                      accumulator1 = {
                        ...accumulator1,
                        [`${name}[${item}].${keyName}`]: error.defaultMsg,
                      };
                    }
                  });
                }
              }
              accumulator1[error.field] = error.defaultMsg;

              return accumulator1;
            },
            {} as any // Set initial value for accumulator
          );
          setServerError(errorObject);
          formik.setErrors(errorObject);
          setErrorsByPage((prev) => {
            return { ...prev, [activeStep]: errorObject };
          });
        }
      }
      return false;
    }
  }

  // Formik hook for form management
  const formik: any = useFormik({
    initialValues,
    onSubmit: async (values: any, { setSubmitting }) => {
      let flag = false;

      Object.keys(errorsByPage).forEach((key) => {
        if (Object.keys(errorsByPage[key as any]).length > 0) {
          flag = true;
          return;
        }
      });
console.log(formIdResponse, "FORM ID RESPONSE")
      try {
        // API call
        const api = new MultiStepFormDomainApi(apiConfig());
        const response = await onSubmitForm();
        if (!response) return;
        if (id)
          await api.updateMultiStepFormGetId("mnas", String(id), {
            formId: formId || formIdResponse,
          });
        else
          await api.createMultiStepFormGetId("mnas", {
            formId: formId || formIdResponse,
          });

        navigate("/mnas");
      } catch (error: any) {
        console.log(error)
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleRemoveAssociation = () => {
    formik.setValues({
      ...formik.values,
      [association]: formik.values[association].filter(
        (_: any, index: number) => index !== Number(deleteID)
      ),
    });
    setDeleteModal(false);
  };

  // const handleUploadCallBack = async (responses: any) => {};

  const handleEditorRef = (ref: any) => {
    setEditorData(ref);
  };

  return (
    <React.Fragment>
      <div>
        {/* Breadcrumbs component */}

        <div
          className={`py-[10px]  ${
            isMenuOpen ? "pl-[284px]" : "pl-[60px]"
          } duration-500`}
        >
          <form
            onSubmit={formik.handleSubmit}
            onChange={() => setServerError(null)}
          >
            <MnaForm
              Keyboard={Keyboard}
              Minus={Minus}
              Plus={Plus}
              conditionCategoryList={conditionCategoryList}
              conditionStatusList={conditionStatusList}
              formik={formik}
              linkCategoryList={linkCategoryList}
              mnaCategoryList={mnaCategoryList}
              mnaSubCategoryList={mnaSubCategoryList}
              txDocSummariesList={txDocSummariesList}
              serverError={serverError}
              featuresLoading={featuresLoading}
              setFeaturesLoading={setFeaturesLoading}
              fetchFeatures={fetchFeatures}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              onSubmitForm={onSubmitForm}
              association={association}
              deleteID={deleteID}
              deleteModal={deleteModal}
              handleRemoveAssociation={handleRemoveAssociation}
              setAssociation={setAssociation}
              setDeleteID={setDeleteID}
              setDeleteModal={setDeleteModal}
              sourceType={sourceType}
              sectorList={sectorList}
              errorObj={errorsByPage}
              onEditorRef={handleEditorRef}
            />
          </form>
        </div>
      </div>
      {loading && <Loader loading={loading} />}
    </React.Fragment>
  );
}

export default MnaAddEdit;
