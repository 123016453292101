import { Status, msToMinutes } from "../../pages/DataMigration";

interface MigrationCardProps {
  status: Status;
  id: string;
  title: string;
  icon: string;
  timeElapsed: number;
  timeRemaining: number;
  progress: number;
  startMigration: (domain: string) => void;
  controller: AbortController;
}

export function MigrationCard({
                                status,
                                id,
                                title,
                                icon,
                                timeElapsed,
                                timeRemaining,
                                progress,
                                startMigration,
                                controller,
                              }: MigrationCardProps) {

  return (
      <div
          key={id}
          className="border border-[#E3E2E9] flex flex-col gap-6 p-3 rounded"
      >
        <div className="flex items-start justify-between w-full">
          <div className="flex items-center gap-2.5">
            <img src={icon} alt="icon" className="w-8 h-8" />
            <p className="text-[#181825] text-sm">{title}</p>
          </div>
          {status !== "not-started" ? (
              <div className="p-1 rounded-full flex flex-col items-center gap-[7px]">
                <p
                    className={`${
                        status === "completed"
                            ? "bg-[#EAFFF6] text-[#2C6A16]"
                            : status === "failed"
                                ? "bg-[#FFF1F1] text-[#EE2A2A]"
                                : "bg-[#F7F8FF] text-[#3B97FF]"
                    } font-semibold px-[15px] py-[7.5px] rounded-2xl text-center text-[12px]`}
                >
                  {status === "completed"
                      ? "Completed"
                      : status === "loading"
                          ? "In Progress"
                          : "Failed"}
                </p>
              </div>
          ) : null}
        </div>
        <div className="flex flex-col gap-1">
          <p className="text-sm">
            {status === "loading"
                ? "Migration progress"
                : status === "completed"
                    ? "Migration Completed"
                    : status === "failed"
                        ? "Migration Failed"
                        : "Not started yet"}
          </p>
          {status !== "not-started" ? (
              <div className="flex items-center justify-between w-full gap-12">
                <div className="flex flex-col w-[80%] justify-between gap-2 text-sm">
                  <div className="w-full bg-[#F0F2F5] rounded-lg h-2">
                    <div
                        style={{
                          width: `${progress}%`,
                          transition: "width 0.2s ease-in-out",
                        }}
                        className="bg-[#F56630] h-full rounded-lg"
                    />
                  </div>
                  <section className="w-full flex gap-2.5 justify-between items-center">
                    <div className="flex flex-wrap items-center gap-2">
                      <p>Time Elapsed: {timeElapsed} secs</p>
                      <p className="bg-[#98A2B3] size-2 flex-shrink-0 rounded-full"></p>
                      <p>Time Remaining: {msToMinutes(timeRemaining)} min</p>
                    </div>
                    <p>{progress}%</p>
                  </section>
                </div>
                {status === "failed" && (
                    <button
                        className="underline text-[#3B97FF] text-sm"
                        onClick={() => startMigration(title)}
                    >
                      restart
                    </button>
                )}
                {status === "loading" && (
                    <div className="flex items-center gap-2">
                      <button
                          className="bg-[#FEF1F1] p-0.5"
                          onClick={() => {
                            controller.abort();
                          }}
                      >
                        <img src="/src/assets/svg/stop.svg" alt="" />
                      </button>
                    </div>
                )}
              </div>
          ) : null}
        </div>
      </div>
  );
}
