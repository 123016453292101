// React
import { useEffect, useState } from "react";
// Third party
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
// OpenAPI
import {
  FileDomainApi,
  NoteResponse,
  NoteDomainApi,
} from "../openapi";
import { apiConfig } from "../components/ConfigurationApi/Configuration";
import { expiredTokenValidation } from "../api/expiredTokenValidation";
import { ResponseFile } from "../interfaces/pages/variedInterfaces";
import { getFileSize } from "../utils";
// Types
import { HandleMenuProps } from "../interfaces/pages/variedInterfaces";

export default function NoteDetails({
  isMenuOpen,
  setIsMenuOpen,
}: HandleMenuProps) {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [notesData, setNotesData] = useState<any>();
  const [domains, setDomains] = useState<
    { name: string; id: number; typeId: number }[]
  >([]);
  const [tags, setTags] = useState<string[]>([]);
  const fetchNoteData = async () => {
    setLoading(true);
    try {
      const api = new NoteDomainApi(apiConfig());

      const response = await api.getNoteById(String(id));
      if (response.data) {
        const data: NoteResponse = response.data;
        setNotesData(data);
        setDomains(
          (data as any)?.domains?.map((domain: any) => {
            return {
              name: domain?.name,
              id: domain?.entityId,
              typeId: domain.typeId,
            };
          })
        );
        setTags(
          data?.tags?.map((tag) => {
            return tag.tag;
          }) || []
        );
      }
    } catch (error) {
      expiredTokenValidation(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchNoteData();
  }, [id]);

  const handleFileDownload = async (id: string, type: string) => {
    try {
      // Set Authorization header
      const headers: {} = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      const api = new FileDomainApi(apiConfig());
      console.log(api, type);
      const response = await api.downloadFile(id);
      console.log(id, response);
      const imageUrl = (response as ResponseFile).config.url;

      // Getting the image url
      const imageResponse = await fetch(imageUrl, { headers });

      // If the request image response it's ok create Blob with the imageResponse
      if (imageResponse.ok) {
        const imageBlob = await imageResponse.blob();

        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(imageBlob);

        // Config file name
        const parts = type.split(".");
        const fileName = parts[0];
        downloadLink.download = `${fileName}.${parts[1] || "png"}`;

        // Add url at document body and simulate click to start the donwload
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        return;
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  // Function to convert <picture> tags to <div className="image-container"> with <img> tags
  const convertPictureToDiv = (htmlContent: any) => {
    const pictureRegex = /<picture>(.*?)<\/picture>/g;

    return htmlContent.replace(pictureRegex, (match: any, content: any) => {
      const sourceTags = Array.from(
        content.matchAll(/<source[^>]*srcset="([^"]*)"[^>]*>/g)
      );
      const imgTagMatch = content.match(/<img[^>]*src="([^"]*)"[^>]*>/);

      const srcSetArray = sourceTags
        .map((tag: any) => {
          const srcSetValue = tag[1].replace(/&amp;/g, "&");
          return srcSetValue;
        })
        .filter((srcSet) => srcSet);

      const fallbackImage = imgTagMatch
        ? imgTagMatch[1].replace(/&amp;/g, "&")
        : ""; // Decode fallback image URL

      const srcSetString = srcSetArray.join(", ");
      if (!srcSetString && !fallbackImage) {
        return "";
      }

      return `
        <div class="image-container">
          <img
            src="${fallbackImage}" // Fallback image
            sizes="(min-width: 600px) 600px, 100vw"
            alt="Description of the image"
            style="border: 1px solid rgb(15, 15, 15); height: auto; max-width: 100%; width: auto;"
          />
        </div>
      `;
    });
  };

  const convertedContent = convertPictureToDiv(notesData?.content || "");
  console.log(notesData);
  return (
    <div
      className={`py-[2rem] pr-2.5 ${
        isMenuOpen ? "pl-[296px]" : "pl-[70px]"
      } duration-500`}
    >
      <div className="w-full bg-gray-200 dark:bg-gray-900">
        <div className="container-fluid mx-auto flex items-start justify-center">
          <div className="w-full">
            <div className="mx-auto w-full border border-[#E6E6F9] bg-[#fff] rounded-md dark:bg-gray-800 dark:bg-button-dark">
              <div className=" justify-between p-2.5 flex flex-col lg:flex-row items-start lg:items-center bg-[#F9F9FB] rounded-t-md dark:bg-sub-table-light dark:text-white">
                <div className="flex flex-col mt-3 lg:mt-0 md:flex-row items-start md:items-center">
                  {domains?.map((domain: any, index: number) => (
                    <h2
                      key={index}
                      className="text-md lg:text-lg text-primary-dark dark:text-primary-light font-bold"
                    >
                      {domain.name}
                      {`${index !== domains.length - 1 ? "," : ""}`}
                    </h2>
                  ))}
                </div>
                <div className="flex gap-2 flex-col mt-3 lg:mt-0 md:flex-row  items-start md:items-center">
                  {tags?.map((tag: any, index: number) => (
                    <div
                      key={index}
                      className="bg-[#DBE9FF] text-primary-dark font-medium dark:text-primary-light dark:bg-primary-dark text-sm leading-3 py-2 px-3 rounded-full"
                    >
                      {tag}
                    </div>
                  ))}
                </div>
              </div>
              <div className="justify-between flex flex-col lg:flex-row items-start lg:items-center pl-[10px] pr-[45px] py-[10px]">
                <div className="w-full lg:w-1/2 pr-0 lg:pr-48">
                  <h1 className="cursor-pointer text-primary-dark dark:text-primary-light font-bold text-base">
                    {notesData?.title}
                  </h1>
                  <p className="mt-2 text-base text-primary-dark dark:text-primary-light font-bold">
                    {notesData?.sourceType}
                  </p>
                </div>
                <div className="w-full lg:w-1/2">
                  <div className="flex justify-end">
                    <span className="group relative cursor-pointer">
                      <div className="absolute bottom-10 lg:-bottom-9 lg:right-3  -translate-x-[50%] hidden group-hover:block w-[350px]">
                        <div className="bg-[#fff] text-[#393C51]  dark:text-white dark:bg-primary-dark  transition-all  text-sm  px-[14px] pt-[12px] pb-[17px] rounded-[10px] border border-[#4185EE] ">
                          <ul className="max-w-md space-y-1 text-dark list-disc list-inside dark:text-white">
                            <li>
                              Date Published:{" "}
                              {moment(notesData?.datePublished).format(
                                "MM/DD/yyyy hh:mm a"
                              )}
                            </li>
                            <li>
                              Date Created:{" "}
                              {moment(notesData?.dateCreated).format(
                                "MM/DD/yyyy hh:mm a"
                              )}{" "}
                              {notesData?.createdBy?.username}
                            </li>
                            <li>
                              Date Modified:{" "}
                              {moment(notesData?.dateModified).format(
                                "MM/DD/yyyy hh:mm a"
                              )}{" "}
                              {notesData?.lastModifiedBy?.username}
                            </li>
                          </ul>
                          <div className=" bottom-0 right-[39px]  -translate-x-1/2 translate-y-1/2 absolute  lg:bottom-8 lg:right-0  rotate-[135deg] transform   lg:translate-x-1/2  lg:-translate-y-1/2 lg:rotate-45 w-4 h-4 bg-white border-r border-t border-[#4185EE] dark:bg-primary-dark"></div>{" "}
                        </div>
                      </div>
                      <h2 className=" text-[#888888] dark:text-primary-light font-medium  text-sm leading-6 lg:mt-0 lg:text-end">
                        {moment(notesData?.datePublished).format(
                          "MM/DD/yyyy hh:mm a"
                        )}
                      </h2>
                    </span>
                  </div>

                  <p className="mt-2   text-md text-[#888888] dark:text-primary-light font-medium  text-sm leading-6 lg:mt-0 lg:text-end">
                    {notesData?.sourceDetail && notesData?.author1
                      ? `${notesData?.sourceDetail} - ${notesData?.author1}`
                      : notesData?.author1
                      ? notesData?.author1
                      : notesData?.sourceDetail
                      ? notesData?.sourceDetail
                      : ""}
                  </p>
                  {(notesData?.sentiment === -1 ||
                    notesData?.sentiment === 0 ||
                    notesData?.sentiment === 1) && (
                    <p className="text-[#888888] dark:text-primary-light font-medium text-sm leading-6 lg:mt-0 lg:text-end">
                      Sentiment:{" "}
                      {notesData?.sentiment === -1
                        ? "Negative"
                        : notesData?.sentiment === 0
                        ? "Neutral"
                        : "Positive"}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex flex-col lg:flex-row pl-[10px] pb-[27px] ">
                <div className="flex flex-col lg:flex-row w-full lg:w-11/12 items-start lg:items-center mb-8 lg:mb-0">
                  <div
                    className="ql-container ql-snow"
                    style={{ position: "relative" }}
                  >
                    <div
                      className="ql-editor p-0"
                      dangerouslySetInnerHTML={{
                        __html: convertedContent || "",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
              {notesData?.files?.length! > 0 && (
                <div className="mt-6 border-t border-[#DDDBEE]">
                  <div className="px-4 py-6">
                    <div className="text-sm font-medium leading-6 text-gray-900 mb-3">
                      Files
                    </div>
                    <ul className="divide-y divide-[#DDDBEE] rounded-md border border-[#DDDBEE]">
                      {notesData?.files?.map((file: any, index: number) => (
                        <li
                          key={index}
                          className="flex items-center justify-between py-4 pl-4 pr-5 leading-6"
                        >
                          <div className="flex items-center w-full">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M16 11L12 15M12 15L8 11M12 15V3M21 15V17C21 18.1046 20.1046 19 19 19H5C3.89543 19 3 18.1046 3 17V15"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            <div className="ml-4 flex items-center justify-between gap-5 w-full">
                              <div className="float-start">
                                <button
                                  className="truncate font-medium w-full"
                                  onClick={() =>
                                    handleFileDownload(
                                      file.fileId,
                                      file.filename
                                    )
                                  }
                                >
                                  {file.filename}
                                </button>
                              </div>
                              <div className="flex-shrink-0 text-gray-400 w-1/3">
                                {getFileSize(file.size)}
                              </div>
                              <div className="w-1/3">
                                {moment(file?.dateModified).format(
                                  "MM/DD/YYYY hh:mm:ss"
                                )}
                              </div>
                            </div>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </div>
            {/* Card code block end */}
          </div>
        </div>
      </div>
    </div>
  );
}
