import React, { useState, useRef, useEffect } from "react";

// Api
import { apiConfig } from "../ConfigurationApi/Configuration";
import { MnaDomainApi, RegulatoryDomainApi } from "../../openapi";
// Images
import checkmark from "../../assets/images/checkmark.png";
import close from "../../assets/svg/close.svg";
// Components
import Loader from "../Loader";
import InputFieldMna from "./InputFieldMna";
// Utils
import { editClosingDateLocally } from "./EditableFieldsUtilities/editableFieldsUtils";
import { sendUpdatedDataToServer } from "./EditableFieldsUtilities/editableFieldsUtils";
import IndividualFieldTextEditor from "./IndividualFieldTextEditor";
import { removeEmptyHTML } from "../../utils";
// Third Party
import ReactQuill from "react-quill";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";

interface Sector {
  label: string;
  value: number;
}

interface EditableFieldMnaProps {
  data?: any;
  fieldName: string;
  fieldtype: "text" | "select" | "date" | "textEditor";
  sectorList?: Sector[];
  dateIndex?: any;
  fullData?: any;
  dateItemValue?: string;
  setDate?: any;
  setFetchMnaDetails?: any;
  setLocalData?: any;
  isRegulatory?: boolean;
  fetchMnaData: () => Promise<void>;
}

const EditableFieldMna: React.FC<EditableFieldMnaProps> = ({
  data,
  fieldName,
  fieldtype,
  sectorList = [],
  dateIndex,
  fullData,
  dateItemValue,
  setLocalData,
  fetchMnaData,
  isRegulatory,
}) => {
  const [activeRow, setActiveRow] = useState(
    fieldtype === "date" && dateItemValue === null
  );
  const [loading, setLoading] = useState(false);
  const [initialValue, setInitialValue] = useState(data || "");
  const [editorData, setEditorData] = useState({ ref: null, keyName: "" });

  const $description = useRef<ReactQuill | null>(null);

  const submitButtonRef = useRef<HTMLButtonElement | null>(null);
  const formatDate = (dateString: string) => {
    const [year, month, day] = dateString.split("-");
    return `${month}/${day}/${year}`;
  };

  const { id } = useParams();
  const safeId = id!;

  const formik = useFormik({
    initialValues: {
      inputValue: initialValue,
    },

    onSubmit: async (values) => {
      if (fieldtype === "date" && fullData[fieldName].at(-1).isNew) {
        editClosingDateLocally(
          fieldName,
          dateIndex,
          values.inputValue,
          setLocalData
        );

        await sendUpdatedDataToServer(
          fieldName,
          fullData,
          safeId,
          setLoading,
          setLocalData,
          values.inputValue
        );
        await fetchMnaData();
        setActiveRow(false);
        return;
      }

      if (fieldtype === "date") {
        setInitialValue(formatDate(values.inputValue));
      } else {
        setInitialValue(values.inputValue);
      }

      let patchValue;

      if (
        fieldName === "outDates" ||
        fieldName === "brokerClosingDates" ||
        fieldName === "financingDates"
      ) {
        if (fieldtype === "text") {
          patchValue = {
            edit: {
              [String(fullData?.[fieldName]?.[dateIndex]?.id)]: {
                mnaId: id,
                comment: values.inputValue,
                dateEvent: fullData?.[fieldName]?.[dateIndex]?.dateEvent,
              },
            },
          };
        } else if (fieldtype === "date" && values.inputValue) {
          patchValue = {
            edit: {
              [String(fullData?.[fieldName]?.[dateIndex]?.id)]: {
                mnaId: id,
                dateEvent: values.inputValue,
                comment: fullData?.[fieldName]?.[dateIndex]?.comment,
              },
            },
          };
        }
      }else {
        patchValue = values.inputValue;
      }

      const jsonPatch = [
        {
          op: "replace",
          path: `/${fieldName}`,
          value: patchValue,
        },
      ];

      if(isRegulatory){
        try {
          const api = new RegulatoryDomainApi(apiConfig());
  
          if (id) {
            await api.partiallyUpdateRegulatory(String(id), jsonPatch);
          }
          await fetchMnaData();
        } finally {
          setActiveRow(false);
        }
        return
      }

      try {
        const api = new MnaDomainApi(apiConfig());

        if (id) {
          await api.partiallyUpdateMna(String(id), jsonPatch);
        }
        await fetchMnaData();
      } finally {
        setActiveRow(false);
      }
    },

    enableReinitialize: true,
  });

  useEffect(() => {
    setInitialValue(data || "");
    formik.setFieldValue("inputValue", data || "");
  }, [data]);

  const handleEdit = () => {
    setActiveRow(true);
  };

  const handleCancel = () => {
    setActiveRow(false);

    formik.setFieldValue("inputValue", initialValue);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (e.relatedTarget === submitButtonRef.current) {
      return;
    }
    handleCancel();
  };

  let selectedSector = formik.values.inputValue;
  if (formik.values.inputValue && fieldName === "sectorTarget") {
    const index = Number(formik.values.inputValue) - 1;
    if (index >= 0 && index < sectorList.length) {
      selectedSector = sectorList[index].label;
    }
  }

  // Text editor

  const handleEditorRef = (ref: any) => {
    setEditorData(ref);
  };

  return (
    <div
      className={`flex w-full h-full relative ${
        fieldtype === "date"
          ? "justify-end items-center"
          : "justify-between items-start"
      }`}
    >
      {" "}
      {loading && <Loader loading={loading} />}
      <div className="w-full">
        <div onDoubleClick={handleEdit} className="w-full">
          {activeRow ? (
            <form onSubmit={formik.handleSubmit} className="w-full">
              {fieldtype === "select" ? (
                <div className="w-full h-full ">
                  <InputFieldMna
                    label={fieldName}
                    id={fieldName}
                    name="inputValue"
                    type="select"
                    optionList={sectorList}
                    placeholder="Sector"
                    serverError={""}
                    formik={formik}
                    labelKey="label"
                    valueKey="value"
                    showIcon
                    bgColor={"#fff"}
                    last
                    autoSubmit={true}
                    cancel={handleBlur}
                  />
                </div>
              ) : fieldtype === "date" ? (
                <div className="w-full">
                  <InputFieldMna
                    label="Date"
                    id="inputValue"
                    name="date"
                    type="date"
                    placeholder="date"
                    showIcon
                    bgColor={"#fff"}
                    last={true}
                    formik={formik}
                    cancel={handleCancel}
                  />
                </div>
              ) : fieldtype == "textEditor" ? (
                <IndividualFieldTextEditor
                  formik={formik}
                  id="description"
                  quillRef={$description}
                  name={"inputValue"}
                  isDynamic
                  value={formik.values.inputValue}
                  label="Descrition"
                  onEditorRef={handleEditorRef}
                />
              ) : (
                <div className="">
                  <input
                    type="text"
                    name="inputValue"
                    autoFocus
                    value={formik.values.inputValue}
                    onChange={formik.handleChange}
                    className="rounded-sm px-2 py-1 my-1  box-border border border-primary-default focus:outline-none w-full h-full"
                    onBlur={handleBlur}
                  />
                </div>
              )}
              {fieldtype === "textEditor" ? (
                <div className="flex justify-end gap-3 mt-2">
                  <button onClick={handleCancel} className="font-medium">
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="font-medium bg-blue-primary text-body rounded-md py-2 px-4"
                  >
                    Save
                  </button>
                </div>
              ) : (
                <div
                  className={`absolute right-[0px] flex space-x-[3px] shadow-2xl ${
                    fieldtype === "select" ? "hidden" : "top-9"
                  }`}
                >
                  {" "}
                  {fieldtype !== "date" && (
                    <>
                      <button
                        type="submit"
                        ref={submitButtonRef}
                        className="rounded-sm p-1 z-10 bg-white shadow-md hover:shadow-lg transition-shadow duration-200"
                      >
                        <img
                          className="w-3 h-3"
                          src={checkmark}
                          alt="checkmark"
                        />
                      </button>

                      <button
                        type="button"
                        onClick={handleCancel}
                        className="rounded-sm p-1 z-10 bg-white shadow-md hover:shadow-lg transition-shadow duration-200"
                      >
                        <img src={close} className="w-3 h-3" alt="close" />
                      </button>
                    </>
                  )}
                </div>
              )}
            </form>
          ) : (
            <div
              className={` rounded-sm cursor-text flex flex-col align-center break-words whitespace-normal `}
            >
              {initialValue ? (
                <>
                  <span
                    className={`hover:bg-button-dark hover:bg-opacity-15 rounded-md p-2 ${
                      fieldtype === "select"
                        ? ""
                        : fieldtype === "text"
                        ? "-mr-2 self-start"
                        : fieldtype === "date" &&
                          (fieldName === "outDates" ||
                            fieldName === "brokerClosingDates" ||
                            fieldName === "financingDates")
                        ? "self-end"
                        : "-ml-2"
                    }`}
                  >
                    {fieldtype === "select" ? (
                      selectedSector
                    ) : fieldtype === "date" ? (
                      formik.values.inputValue
                    ) : fieldtype === "textEditor" ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html:
                            removeEmptyHTML(formik.values.inputValue) || "",
                        }}
                      />
                    ) : (
                      <span className="break-all">{initialValue}</span>
                    )}
                  </span>
                </>
              ) : (
                <div className=" bg-opacity-10 w-full rounded-md px-2 hover:bg-table-dark hover:bg-opacity-10">
                  <p className="py-2">-</p>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditableFieldMna;
