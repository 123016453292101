// React
import React, { useState } from "react";
// Components
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";
import {apiConfig, axiosInstance} from "../components/ConfigurationApi/Configuration";
import { MigrationButton } from "../components/DataMigration/DataMigrationButton";
import { MigrationCard } from "../components/DataMigration/DataMigrationCard";
// Assets
import dashboardIcon from "../assets/svg/blue-dashboard.svg";
import MNAsDM from "../assets/svg/mnas-data-migration.svg";
import NotesDM from "../assets/svg/notes-data-migration.svg";
import RegulatoryLibraryDM from "../assets/svg/mnas-data-migration.svg";
import FundamentalsDM from "../assets/svg/regulatory-data-migration.svg";
// Navigation
import { useNavigate } from 'react-router-dom';
// OpenAPI
import {
  MnaDomainApiAxiosParamCreator,
  NoteDomainApiAxiosParamCreator,
  RegulatoryDomainApiAxiosParamCreator,
  FundamentalDomainApiAxiosParamCreator,
  DataMigrationDomainApiAxiosParamCreator
} from "../openapi";
// Types
import {HandleMenuProps} from "../interfaces/pages/variedInterfaces";

type MigrationEventData = {
  id: number;
  name: string;
  progress: number;
  timeRemaining: number;
  timeElapsed: number;
};

const configuration = apiConfig();
const mnaApi = MnaDomainApiAxiosParamCreator(configuration);
const noteApi = NoteDomainApiAxiosParamCreator(configuration);
const regulatoryApi = RegulatoryDomainApiAxiosParamCreator(configuration);
const fundamentalsApi = FundamentalDomainApiAxiosParamCreator(configuration);
const dataMigrationApi = DataMigrationDomainApiAxiosParamCreator(configuration);


const MigrationControl = [
  {
    id: "1",
    title: "MNAs",
    icon: MNAsDM,
    apiFunction: async (options: any) => {
      const requestConfig = await mnaApi.migrateMnaToElasticSearch(options);
      return axiosInstance({ ...requestConfig, method: 'PUT' });
    }
  },
  {
    id: "2",
    title: "Notes",
    icon: NotesDM,
    apiFunction: async (options: any) => {
      const requestConfig = await noteApi.migrateNoteToElasticSearch(options);
      return axiosInstance({ ...requestConfig, method: 'PUT' });
    }
  },
  {
    id: "3",
    title: "Regulatory Library",
    icon: RegulatoryLibraryDM,
    apiFunction: async (options: any) => {
      const requestConfig = await regulatoryApi.migrateRegulatorsToElasticSearch(options);
      return axiosInstance({ ...requestConfig, method: 'PUT' });
    }
  },
  {
    id: "4",
    title: "Fundamentals",
    icon: FundamentalsDM,
    apiFunction: async (options: any) => {
      const requestConfig = await fundamentalsApi.migrateFundamentals(options);
      return axiosInstance({ ...requestConfig, method: 'PUT' });
    }
  },
];

const MigrationStatus = [
  {
    id: "1",
    title: "MNAs",
    icon: MNAsDM,
    statusFunction: async () => {
      const requestConfig = await dataMigrationApi.mnaMigrationStatus();
      return axiosInstance(requestConfig);
    }
  },
  {
    id: "2",
    title: "Notes",
    icon: NotesDM,
    statusFunction: async () => {
      const requestConfig = await dataMigrationApi.notesMigrationStatus();
      return axiosInstance(requestConfig);
    }
  },
  {
    id: "3",
    title: "Regulatory Library",
    icon: RegulatoryLibraryDM,
    statusFunction: async () => {
      const requestConfig = await dataMigrationApi.regulatoryMigrationStatus();
      return axiosInstance(requestConfig);
    }
  },
  {
    id: "4",
    title: "Fundamentals",
    icon: FundamentalsDM,
    statusFunction: async () => {
      const requestConfig = await dataMigrationApi.fundamentalsMigrationStatus();
      return axiosInstance(requestConfig);
    }
  },
];


export const msToMinutes = (ms: number) => Math.round(ms / 60000000);
const toJSON = (value: string | undefined) => {
  if (!value) return;
  try {
    const data = value.replace(/data:/, "");
    return JSON.parse(data) as MigrationEventData;
  } catch (err) {
    console.error("Error parsing response:", err);
  }
};

export type Status = "loading" | "completed" | "not-started" | "failed";

export default function DataMigration({ isMenuOpen, setIsMenuOpen }: HandleMenuProps) {
  const [migrationProgress, setMigrationProgress] = useState<{ [key: string]: MigrationEventData }>({});
  const [status, setStatus] = useState<{ [key: string]: Status }>({});
  const [controller, setController] = useState<{ [key: string]: AbortController }>({});

  const role = localStorage.getItem("role");
  const navigate = useNavigate();

  const startMigration = async (domain: string) => {
    const migrationItem = MigrationControl.find(({ title }) => title === domain);
    if (!migrationItem) return;

    setStatus((prevStatus) => ({ ...prevStatus, [domain]: "loading" }));
    const controller = new AbortController();
    setController((prevController) => ({ ...prevController, [domain]: controller }));

    setMigrationProgress((prevProgress) => ({
      ...prevProgress,
      [domain]: {
        id: 0,
        name: "Temporary",
        progress: 0,
        timeElapsed: 0,
        timeRemaining: 0
      },
    }));

    const progressInterval = setInterval(() => {
      setMigrationProgress((prevProgress) => {
        const currentProgress = prevProgress[domain]?.progress || 0;
        const randomIncrement = Math.random() * 0.4 + 0.1;
        const newProgress = Math.min(100, currentProgress + randomIncrement);

        return {
          ...prevProgress,
          [domain]: {
            ...prevProgress[domain],
            progress: parseFloat(newProgress.toFixed(1)),
          },
        };
      });
    }, 100);

    try {
      const response = await migrationItem.apiFunction({ signal: controller.signal });

      clearInterval(progressInterval);

      if (response.status === 200) {
        setMigrationProgress((prevProgress) => ({
          ...prevProgress,
          [domain]: { ...prevProgress[domain], progress: 100 },
        }));
        setStatus((prevStatus) => ({ ...prevStatus, [domain]: "completed" }));
      } else {
        setStatus((prevStatus) => ({ ...prevStatus, [domain]: "failed" }));
      }
    } catch (error: any) {
      clearInterval(progressInterval);
      setStatus((prevStatus) => ({ ...prevStatus, [domain]: "failed" }));
      if (error?.name !== "AbortError") console.error("Error during migration:", error);
    }
  };



  const fetchStatus = async (domain: string) => {
    const statusItem = MigrationStatus.find(({ title }) => title === domain);
    if (!statusItem) return;

    try {
      const response = await statusItem.statusFunction();
      const data = response.data;
      setMigrationProgress((prevProgress) => ({
        ...prevProgress,
        [domain]: data,
      }));
    } catch (error) {
      console.error("Error fetching migration status:", error);
    }
  };

  return (
      <React.Fragment>
        {role === "ROLE_ADMIN" ? (
            <div className={`py-[2rem] pr-[2rem] flex flex-col gap-7 ${isMenuOpen ? "pl-[316px]" : "pl-[92px]"} duration-500`}>
              <Breadcrumbs breadcrumbs={["Data Migration"]} icon={dashboardIcon} />
              <div className="flex flex-col gap-6">
                <div className="flex flex-col gap-5 bg-white rounded-lg p-7">
                  <h3 className="text-[#181825] font-extrabold text-xl">Migration Control</h3>
                  <div className="grid gap-2 md:grid-cols-2 xl:grid-cols-4">
                    {MigrationControl.map((item) => (
                        <MigrationButton key={item.id} item={item} status={status[item.title] || "not-started"} startMigration={startMigration} />
                    ))}
                  </div>
                </div>

                <div className="flex flex-col gap-5 bg-white rounded-lg p-7">
                  <h3 className="text-[#181825] font-extrabold text-xl">Migration Status Area</h3>
                  <div className="grid gap-2 lg:grid-cols-2">
                    {MigrationStatus.map(({ title, id, icon }) => {
                      const progressData = migrationProgress[title] || { progress: 0, timeElapsed: 0, timeRemaining: 0 };
                      return (
                          <MigrationCard key={id} status={status[title] || "not-started"} id={id} title={title} icon={icon}
                                         timeElapsed={progressData.timeElapsed} timeRemaining={progressData.timeRemaining} progress={progressData.progress}
                                         startMigration={startMigration} controller={controller[title]} />
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
        ) : (
            <div className="w-full h-screen flex flex-col items-center justify-center border">
              <h2 className="text-5xl font-extrabold">401</h2>
              <h2 className="text-5xl font-extrabold">UNAUTHORIZED</h2>
              <p onClick={() => navigate(-1)} className="text-blue-primary cursor-pointer hover:underline mt-5"> Go back</p>
            </div>
        )}
      </React.Fragment>
  );
}
