// React
import React, { useCallback, useEffect, useState } from "react";
// Third party
import { useNavigate, useParams } from "react-router-dom";
// Components
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";
import Loader from "../components/Loader";
// OpenAPI
import { apiConfig } from "../components/ConfigurationApi/Configuration";
import { expiredTokenValidation } from "../api/expiredTokenValidation";
import {
  FileDomainApi,
  FundamentalDomainApi,
  SectorDomainApi,
} from "../openapi";
// Images
import fundamentalIcon from "../assets/svg/blue-fundamental.svg";
import Table from "../components/Tables/RecentEvent";
import { Columns } from "../components/Columns/Columns";
import { ResponseFile, Sort } from "../interfaces/pages/variedInterfaces";
import { getFileSize } from "../utils";
import moment from "moment";
import { getApiData } from "../controller/getApiDataConfiguration";
// Types
import { HandleMenuProps } from "../interfaces/pages/variedInterfaces";

  const FundamentalDetails: React.FC<HandleMenuProps> = ({
    isMenuOpen,
    setIsMenuOpen,
  }) => {
  const [data, setData] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [content, setContent] = useState<string | undefined>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const tabsContent = [
    {
      header: "What Matters",
      content: (
        <>
          <div className="border border-[#E4E3ED] rounded-md h-full">
            <div className=" px-4 pt-4 pb-4  dark:bg-black text-title  dark:text-white text-lg font-semibold flex items-center justify-between rounded-tl-md rounded-tr-md">
              What Matters
            </div>
            <div className="  px-3.5 py-[19px] ">
              <div
                className="ql-container ql-snow"
                style={{ position: "relative" }}
              >
                <div
                  className="ql-editor"
                  dangerouslySetInnerHTML={{
                    __html: data?.whatMatters,
                  }}
                />
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      header: "Business Description",
      content: (
        <div className="border border-[#E4E3ED] rounded-md h-full">
          <div className=" px-3.5 py-[19px]  text-title dark:bg-black  text-lg font-semibold flex items-center justify-between rounded-tl-md rounded-tr-md">
            Business Description
          </div>
          <div className="] px-3.5 py-[19px] ">
            <div
              className="ql-container ql-snow"
              style={{ position: "relative" }}
            >
              <div
                className="ql-editor"
                dangerouslySetInnerHTML={{
                  __html: data?.businessDesc,
                }}
              />
            </div>
          </div>
        </div>
      ),
    },
    {
      header: "Company History",
      content: (
        <>
          <div className="border border-[#E4E3ED] rounded-md h-full">
            <div className=" px-3.5 py-[19px] text-title dark:bg-black  text-lg font-semibold flex items-center justify-between rounded-tl-md rounded-tr-md">
              Company History
            </div>
            <div className="] px-3.5 py-[19px] ">
              <div
                className="ql-container ql-snow"
                style={{ position: "relative" }}
              >
                <div
                  className="ql-editor"
                  dangerouslySetInnerHTML={{
                    __html: data?.companyHistory,
                  }}
                />
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      header: " Company Basics",
      content: (
        <>
          <div className="border border-[#E4E3ED] rounded-md h-full">
            <div className=" px-3.5 py-[19px] dark:bg-black text-title  text-lg font-semibold flex items-center justify-between rounded-tl-md rounded-tr-md">
              Company Basics
            </div>
            <div className="] px-3.5 py-[19px] ">
              <div
                className="ql-container ql-snow"
                style={{ position: "relative" }}
              >
                <div
                  className="ql-editor"
                  dangerouslySetInnerHTML={{
                    __html: data?.companyBasics,
                  }}
                />
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      header: "Drivers / Valuation",
      content: (
        <>
          <div className="border border-[#E4E3ED] rounded-md h-full">
            <div className=" px-3.5 py-[19px] text-title dark:bg-black  text-lg font-semibold flex items-center justify-between rounded-tl-md rounded-tr-md">
              Drivers / Valuation
            </div>
            <div className="] px-3.5 py-[19px] ">
              <div
                className="ql-container ql-snow"
                style={{ position: "relative" }}
              >
                <div
                  className="ql-editor"
                  dangerouslySetInnerHTML={{
                    __html: data?.driversValuation,
                  }}
                />
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      header: "Valuation History",
      content: (
        <>
          <div className="border border-[#E4E3ED] rounded-md ">
            <div className=" px-3.5 py-[19px] dark:bg-black border-[#E5E4FB] text-title  text-lg font-semibold flex items-center justify-between rounded-tl-md rounded-tr-md">
              Valuation History
            </div>
            <div className="px-[33px] py-[25px]">
              <div
                className="ql-container ql-snow"
                style={{ position: "relative" }}
              >
                <div
                  className="ql-editor"
                  dangerouslySetInnerHTML={{
                    __html: data?.valuationHistory,
                  }}
                />
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      header: "Blomberg info",
      content: <></>,
    },
  ];
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    fetchFundamentalData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);


  const openModal = (content: string) => {
    setContent(content);

    setIsModalOpen(true);
  };
  const fetchFundamentalNoteList = useCallback(
    async (pageIndex: number, pageSize: number, sortBy: Sort[]) => {
      const getFundamentalNoteList = new getApiData();
      return getFundamentalNoteList.getNoteDomainApi2(
        String(id),
        pageIndex,
        pageSize,
        sortBy,
        "Fundamental"
      );
    },
    [id]
  );


  const fetchFundamentalData = async () => {
    setLoading(true);
    try {
      const api = new FundamentalDomainApi(apiConfig());
      const response = await api.getFundamentalById(String(id));
      let sectorId;
      if (response.data.sectorId) {
        sectorId = await fetchSectorNameById(response.data.sectorId);
      }

      setData({ ...response?.data, sectorId } || {});
    } catch (error) {
      expiredTokenValidation(error);
    }
    setLoading(false);
  };

  const columnsInstance = new Columns();

  const fetchSectorNameById = async (sectorId: number) => {
    try {
      const api = new SectorDomainApi(apiConfig());
      const response = await api.getSectorById(sectorId);
      return response.data?.name || "";
    } catch (error) {
      expiredTokenValidation(error);
    }
  };

  const handleFileDownload = async (id: string, type: string) => {
    try {
      // Set Authorization header
      const headers: {} = {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      };
      const api = new FileDomainApi(apiConfig());
      const response = await api.downloadFile(id);
      const imageUrl = (response as ResponseFile).config.url;

      // Getting the image url
      const imageResponse = await fetch(imageUrl, { headers });

      // If the request image response it's ok create Blob with the imageResponse
      if (imageResponse.ok) {
        const imageBlob = await imageResponse.blob();

        const downloadLink = document.createElement("a");
        downloadLink.href = window.URL.createObjectURL(imageBlob);

        // Config file name
        const parts = type.split(".");
        const fileName = parts[0];
        downloadLink.download = `${fileName}.${parts[1] || "png"}`;

        // Add url at document body and simulate click to start the donwload
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
        return;
      }
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  return (
    <React.Fragment>
      {loading && <Loader loading={loading} />}
      <div
        className={`py-[30px] pr-[24px] bg-[#F6F7FA] ${
          isMenuOpen ? "pl-[314px]" : "pl-[90px]"
        } duration-500`}
      >
        <Breadcrumbs
          breadcrumbs={["Fundamentals", `${data?.name || ""}`]}
          // icon={fundamentalIcon}
          addItem={() => navigate("/add-fundamental")}
          // updateItem={() => navigate(`/update-fundamental/${id}`)}
        />
        <div className="pt-[24px] rounded-md">
          <div className="flex flex-col md:flex-row gap-4 ">
            <div className="w-full flex flex-col gap-4 h-full">
              {/* COMPANY INFORMATION */}
              <div >
                <div className=" min-w-full inline-block align-middle">
                  <div className="border border-[#DCDBEE] rounded-md overflow-hidden">
                    <table className="min-w-full dark:bg-primary-dark bg-white ">
                      <caption className="text-left text-[18px] font-semibold text-title dark:bg-black bg-white pb-2 pt-[16px] px-[16px]">
                        Company Information
                      </caption>
                      <tbody className="">
                        <tr>
                          <td className="px-[16px] py-2  text-[#696868]  dark:text-white   text-base font-normal">
                            Ticker:
                          </td>
                          <td className="px-[16px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.ticker}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[16px] py-2  text-[#696868] dark:text-white   text-base font-normal">
                            Sector:
                          </td>
                          <td className="px-[16px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.sectorId}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[16px] py-2  text-[#696868] dark:text-white   text-base font-normal">
                            Incorporation:
                          </td>
                          <td className="px-[16px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.incorp}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[16px] py-2  text-[#696868] dark:text-white   text-base font-normal">
                            Headquarters:
                          </td>
                          <td className="px-[16px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.hq}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[16px] py-2  text-[#696868] dark:text-white   text-base font-normal">
                            Geo Exposure:
                          </td>
                          <td className="px-[16px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.geoExposure}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[16px] py-2  text-[#696868] dark:text-white   text-base font-normal">
                            Date IPO:
                          </td>
                          <td className="px-[16px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {
                              data?.dateIpo &&
                              moment(data?.dateIpo).isValid()
                               ? moment(data?.dateIpo).format("MM/DD/YYYY")
                                : null
                            }
                       
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[16px] pt-2   text-[#696868] dark:text-white   text-base font-normal">
                            Address:
                          </td>
                          <td className="px-[16px] py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            {data?.address}
                          </td>
                        </tr>
                        <tr>
                          <td className="px-[16px] py-2  text-[#696868] dark:text-white   text-base font-normal">
                            {data?.description}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* Price Target */}
              <div className=" overflow-x-auto">
                <div className=" min-w-full inline-block align-middle">
                  <div className="border border-[#DCDBEE] rounded-md overflow-hidden">
                    <table className="min-w-full dark:bg-primary-dark bg-white ">
                      <thead>
                        <td className="px-4 pb-2 pt-4  text-title  dark:text-white   text-[18px] font-semibold font-normal">
                          Price Target
                        </td>
                        <td className="px-4 py-2 pt-4  text-title  dark:text-white   text-base font-normal">
                          Downside
                        </td>
                        <td className="px-4 py-2 pt-4 text-title  dark:text-white   text-base font-normal">
                          Base
                        </td>
                        <td className="px-4 py-2 pt-4 text-title  dark:text-white   text-base font-normal">
                          Upside
                        </td>
                      </thead>
                      <tbody className="">
                        <tr>
                          <td className="px-4 py-2  text-[#696868]  dark:text-white   text-base font-normal">
                            Simple Target:
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            $120.00
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            $160.00
                          </td>
                          <td className="pl-3.5 pr-4 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            $180.00
                          </td>
                        </tr>
                        <tr>
                          <td className="px-4 py-2  text-[#696868] dark:text-white   text-base font-normal">
                            Divident/Other:
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            0.00
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            0.00
                          </td>
                          <td className="pl-3.5 pr-4 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            0.00
                          </td>
                        </tr>
                        <tr>
                          <td className="px-4 py-2  text-[#696868] dark:text-white   text-base font-normal">
                            Total Return:
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            $120.00
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            $160.00
                          </td>
                          <td className="pl-3.5 pr-4 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            $180.00
                          </td>
                        </tr>
                        <tr>
                          <td className="px-4 py-2  text-[#696868] dark:text-white   text-base font-normal">
                            Nominal % Return:
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            -10.00%
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            -16.00%
                          </td>
                          <td className="pl-3.5 pr-4 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            -180.00%
                          </td>
                        </tr>
                        <tr>
                          <td className="px-4 py-2  text-[#696868] dark:text-white   text-base font-normal">
                            Probability:
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left"></td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left"></td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left"></td>
                        </tr>
                        <tr>
                          <td className="px-4 py-2  text-[#696868] dark:text-white font-semibold  text-base font-normal">
                            Risk/Reward:
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white    text-base font-medium text-left">
                            $120.00
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            $160.00
                          </td>
                          <td className="pl-3.5 pr-4 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            $180.00
                          </td>
                        </tr>
                        <tr>
                          <td className="px-4 py-2  text-[#696868] dark:text-white font-semibold   text-base font-normal">
                            Annuluzed Return:
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            $120.00
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            $160.00
                          </td>
                          <td className="pl-3.5 pr-4 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            $180.00
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* Capitalization Summary */}
              <div className=" overflow-x-auto ">
                <div className=" min-w-full inline-block align-middle">
                  <div className="border border-[#DCDBEE] rounded-md overflow-hidden">
                    <table className="min-w-full dark:bg-primary-dark bg-white ">
                      <caption className="text-left text-title text-[18px] font-semibold pt-[16px] dark:bg-table-dark bg-table-light pb-2 px-4">
                        Capitalization Summary
                      </caption>
                      <tbody className="">
                        <tr>
                          <td className="pl-4 pr-3.5 py-2  text-[#696868]  dark:text-white   text-base font-normal">
                            Current Price:
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            $144.00
                          </td>
                        </tr>
                        <tr>
                          <td className="pl-4 pr-3.5 py-2  text-[#696868] dark:text-white   text-base font-normal">
                            Diluted Shares:
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            42.8
                          </td>
                        </tr>
                        <tr>
                          <td className="pl-4 pr-3.5 py-2  text-[#696868] dark:text-white font-semibold  text-base font-normal">
                            Market Cap:
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            6,189.0
                          </td>
                        </tr>
                        <tr>
                          <td className="pl-4 pr-3.5 py-2  text-[#696868] dark:text-white   text-base font-normal">
                            Net Debit:
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            3,799.8
                          </td>
                        </tr>
                        <tr>
                          <td className="pl-4 pr-3.5 py-2  text-[#696868] dark:text-white   text-base font-normal">
                            Enterprice Value:
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            10,144.6
                          </td>
                        </tr>
                        <tr>
                          <td className="pl-4 pr-3.5 pt-2 pb-4  text-[#696868] dark:text-white font-semibold  text-base font-normal">
                            Net Deb/EBITDA:
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white    text-base font-medium text-left">
                            5.2x
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* Valuation Summary */}
              <div className=" overflow-x-auto">
                <div className=" min-w-full inline-block align-middle">
                  <div className="border border-[#DCDBEE] rounded-md overflow-hidden">
                    <table className="min-w-full dark:bg-primary-dark bg-white ">
                      <thead>
                        <td className="pl-4 pr-3.5 pb-2 pt-4  text-title  dark:text-white   text-[18px] font-semibold font-normal">
                          Valuation Summary
                        </td>
                        <td className="px-3.5 pb-2 pt-4  text-title  dark:text-white   text-base font-normal">
                          2023
                        </td>
                        <td className="px-3.5 pb-2 pt-4  text-title  dark:text-white   text-base font-normal">
                          2024
                        </td>
                        <td className="px-3.5 pb-2 pt-4  text-title  dark:text-white   text-base font-normal">
                          2025
                        </td>
                        <td className="pl-3.5 pr-4 pb-2 pt-4  text-title  dark:text-white   text-base font-normal">
                          2026
                        </td>
                      </thead>
                      <tbody className="">
                        <tr>
                          <td className="pl-4 pr-3.5 py-2  text-[#696868]  dark:text-white   text-base font-normal">
                            EV / EBITDA:
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            13.8x
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            8.8x
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            13.8x
                          </td>
                          <td className="pl-3.5 pr-4 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            13.8x
                          </td>
                        </tr>
                        <tr>
                          <td className="pl-4 pr-3.5 text-[#696868] dark:text-white   text-base font-normal">
                            EV / EBIT:
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            16.7x
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            6.7x
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            1.7x
                          </td>
                          <td className="pl-3.5 pr-4 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            7.5x
                          </td>
                        </tr>
                        <tr>
                          <td className="pl-4 pr-3.5 py-2  text-[#696868] dark:text-white   text-base font-normal">
                            P / E:
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            120.0x
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            160.0x
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            180.0x
                          </td>
                          <td className="pl-3.5 pr-4 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            180.0x
                          </td>
                        </tr>
                        <tr>
                          <td className="pl-4 pr-3.5 py-2  text-[#696868] dark:text-white   text-base font-normal">
                            FCF Yield:
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            0.6%
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            7.6%
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            10.6%
                          </td>
                          <td className="pl-3.5 pr-4 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            10.9%
                          </td>
                        </tr>
                        <tr>
                          <td className="pl-4 pr-3.5 pt-2 pb-4  text-[#696868] dark:text-white   text-base font-normal">
                            Divident yield:
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            0.0%
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            0.0%
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            0.0%
                          </td>
                          <td className="pl-3.5 pr-4 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            0.0%
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* Attached Files */}
              <div className="overflow-x-auto bg-white">
                <div className="min-w-full inline-block align-middle">
                  <div className="border border-[#DCDBEE] rounded-md overflow-hidden">
                    <table className="min-w-full dark:bg-table-dark  rounded-md">
                      <caption className="text-left text-[18px] font-semibold text-title dark:bg-black  pb-2 pt-4 px-4">
                        Attached Files
                      </caption>
                      <thead className="= dark:bg-black">
                        <tr></tr>
                      </thead>
                      <tbody className="">
                        {data?.files?.length > 0 ? (
                          data?.files?.map((item: any, index: number) => (
                            <tr key={`files-${index}`}>
                              <td className="px-3.5 py-2  text-[#000817] dark:text-white   text-base font-medium text-left">
                                <button
                                  onClick={() =>
                                    handleFileDownload(
                                      item.fileId,
                                      item.filename
                                    )
                                  }
                                >
                                  {item.filename}
                                </button>
                              </td>
                              <td className="px-3.5 py-2  text-[#000817] dark:text-white   text-base  text-left">
                                {getFileSize(item.size)}
                              </td>
                              <td className="px-3.5 py-2  text-[#B2B2B2] dark:text-white   text-base  text-left">
                                {moment(item?.dateModified).format(
                                  "MM/DD/YYYY hh:mm A"
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr className="text-center">
                            <td
                              colSpan={3}
                              className="text-primary font-bold text-base p-4"
                            >
                              None
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* About Fundamental */}
              <div className=" overflow-x-auto">
                <div className=" min-w-full inline-block align-middle">
                  <div className="border border-[#DCDBEE] rounded-md overflow-hidden">
                    <table className="min-w-full dark:bg-primary-dark bg-white ">
                      <caption className="text-left text-title text-[18px] font-semibold pt-[16px] dark:bg-table-dark bg-table-light pb-2 pr-4 pl-3.5">
                        About Fundamental
                      </caption>
                      <tbody className="">
                        <tr>
                          <td className="pl-4 pr-3.5 py-2  text-[#696868]  dark:text-white   text-base font-normal">
                            Date Publised:
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            04.03.2024
                          </td>
                        </tr>
                        <tr>
                          <td className="pl-4 pr-3.5 py-2  text-[#696868] dark:text-white   text-base font-normal">
                            Source Type:
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            Article
                          </td>
                        </tr>
                        <tr>
                          <td className="pl-4 pr-3.5 py-2  text-[#696868] dark:text-white font-semibold  text-base font-normal">
                            Author:
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            Amily Smith
                          </td>
                        </tr>
                        <tr>
                          <td className="pl-4 pr-3.5 py-2  text-[#696868] dark:text-white   text-base font-normal">
                            Net Debit:
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            3,799.8
                          </td>
                        </tr>
                        <tr>
                          <td className="pl-4 pr-3.5 pt-2 pb-4  text-[#696868] dark:text-white   text-base font-normal">
                            Source Type:
                          </td>
                          <td className="pl-4 pr-3.5 pt-2 pb-4   text-[#000817] dark:text-white   text-base font-medium text-left">
                            American Express
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full h-full flex flex-col gap-4 dark:bg-primary-dark">
               {/* BUTTONS */}
               <div className="flex gap-4 mb-4 flex-wrap">
                {tabsContent.map((tab, index) => (
                  <button
                    className={`min-w-[130px] h-[36px] px-2 ${activeTab === index && "bg-[#E8F2FD] font-semibold"
                      } text-blue-primary border border-blue-primary  text-sm  rounded-md`}
                    onClick={() => setActiveTab(index)}
                  >
                    {tab.header}
                  </button>
                ))}
              </div>
              {/* <div className="overflow-x-auto mt-[16px]"></div> */}
              {/* CONTENT */}
              <div className="flex flex-col bg-white rounded-md h-full dark:bg-table-dark">
                {tabsContent[activeTab].content}
              </div>
              <div className={"flex gap-4 h-full flex-col"}>
                
                {/* Trading Data */}
                <div className=" border border-[#DCDBEE] rounded-md overflow-hidden bg-white">
                  <table className="min-w-full dark:bg-table-dark">
                    <caption className="text-left text-[18px] font-semibold text-title pt-[16px] pb-2 px-[16px]">
                      Trading Data
                    </caption>
                    <tbody className="">
                      <tr className="flex  justify-between items-center w-full">
                        <td className="px-4 pb-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                          Current Price:
                        </td>
                        <td className="px-4 pb-2  text-sm font-medium text-left">
                          $144.60
                        </td>
                      </tr>
                      <tr className="flex  justify-between items-center w-full">
                        <td className="px-4 pb-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                          Value Traded (3 mon):
                        </td>
                        <td className="px-4 text-sm font-medium text-left">
                          77.57
                        </td>
                      </tr>
                      <tr className="flex  justify-between items-center w-full">
                        <td className="px-4 pb-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                          Short interest (m):
                        </td>
                        <td className="px-4 pb-2 ] text-sm font-medium text-left">
                          5.59
                        </td>
                      </tr>
                      <tr className="flex  justify-between items-center w-full">
                        <td className="px-4 pb-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                          SI % of Float:
                        </td>
                        <td className="px-4 pb-2  text-sm font-medium text-left">
                          13.1%
                        </td>
                      </tr>
                      <tr className="flex  justify-between items-center w-full">
                        <td className="px-4 pb-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                          Impied Volatility:
                        </td>
                        <td className="px-4 pb-2 ] text-sm font-medium text-left">
                          38.0%
                        </td>
                      </tr>
                      <tr className="flex  justify-between items-center w-full">
                        <td className="px-4 pb-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                          52-Weeks High:
                        </td>
                        <td className="px-4 pb-2 ] text-sm font-medium text-left">
                          188.65%
                        </td>
                      </tr>
                      <tr className="flex  justify-between items-center w-full">
                        <td className="px-4 pb-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                          52-Weeks Low:
                        </td>
                        <td className="px-4 pb-2 ] text-sm font-medium text-left">
                          109.48%
                        </td>
                      </tr>
                      <tr className="flex  justify-between items-center w-full">
                        <td className="px-[16px] pb-[16px]  text-[#000817] dark:text-white   text-sm font-medium text-left">
                          YTD return:
                        </td>
                        <td className="px-[16px] pb-[16px] text-sm font-medium text-left">
                          6.1%
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* Key Dates */}
                <div className=" border border-[#DCDBEE] rounded-md overflow-hidden bg-white">
                  <table className="min-w-full dark:bg-table-dark bg-table-light">
                    <caption className="text-left dark:bg-table-dark bg-table-light text-[18px] font-semibold text-title pt-[16px] pb-4 px-[16px]">
                      Key Dates
                    </caption>
                    <tbody className="">
                      <tr className="flex  justify-between items-center w-full">
                        <td className="px-4 pb-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                          Trade Inception:
                        </td>
                        <td className="px-3.5 pb-2 text-sm font-medium text-left">
                          6.10.2024
                        </td>
                      </tr>
                      <tr className="flex  justify-between items-center w-full">
                        <td className="px-3.5 pb-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                          Today:
                        </td>
                        <td className="px-3.5 pb-2 text-sm font-medium text-left">
                          6.11.2024
                        </td>
                      </tr>
                      <tr className="flex  justify-between items-center w-full">
                        <td className="px-3.5 pb-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                          Elapsed Duration:
                        </td>
                        <td className="px-3.5 pb-2 text-sm font-medium text-left">
                          1 day
                        </td>
                      </tr>
                      <tr className="flex  justify-between items-center w-full">
                        <td className="px-3.5 pb-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                          Next Earnings
                        </td>
                        <td className="px-3.5 pb-2 text-sm font-medium text-left">
                          6.11.2024
                        </td>
                      </tr>
                      <tr className="flex  justify-between items-center w-full">
                        <td className="px-3.5 pb-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                          Catalyst Expected
                        </td>
                        <td className="px-3.5 pb-2 text-sm font-medium text-left">
                          $144.60
                        </td>
                      </tr>
                      <tr className="flex  justify-between items-center w-full">
                        <td className="px-3.5 pb-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                          Timing
                        </td>
                        <td className="px-3.5 pb-2 text-sm font-medium text-left">
                          6 monthes
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className={"flex gap-4 h-full flex-col"}>
                {/* Managment */}
                <div className=" border border-[#DCDBEE] rounded-md overflow-hidden dark:bg-table-dark bg-table-light ">
                  <table className="min-w-full dark:bg-table-dark">
                    <caption className="text-left text-[18px] font-semibold text-title pt-[16px] pb-2 px-3.5 ">
                      Managment
                    </caption>
                    <tbody className="">
                      <tr className="flex  justify-between items-center w-full">
                        <td className="pl-4 pr-3.5 pb-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                          8.30%
                        </td>
                        <td className="px-3.5 pb-2 text-[#D70000] text-sm font-medium text-left">
                          Vanguard
                        </td>
                        <td className="pl-3.5 pr-4 pb-2 text-[#007B1B] text-sm font-medium text-left">
                          ATVI
                        </td>
                      </tr>
                      <tr className="flex  justify-between items-center w-full">
                        <td className="pr-3.5 pl-4 pb-2  text-[#000817] dark:text-white   text-sm font-medium text-left">
                          8.30%
                        </td>
                        <td className="px-3.5 pb-2 text-[#D70000] text-sm font-medium text-left">
                          Vanguard
                        </td>
                        <td className="pl-3.5 pr-4 pb-2 text-[#007B1B] text-sm font-medium text-left">
                          ATVI
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/* Key Holders */}
                <div className=" border border-[#DCDBEE] rounded-md overflow-hidden dark:bg-table-dark bg-table-light">
                  <table className="min-w-full dark:bg-table-dark ">
                    <caption className="text-left text-[18px] font-semibold text-title pt-[16px] pb-2 pr-3.5 pl-4">
                      Key Holders
                    </caption>
                    <tbody className="">
                      <tr className="flex  justify-between items-center w-full">
                        <td className="pr-3.5 pl-4 pb-2 text-[#000817] dark:text-white   text-sm font-medium text-left">
                          8.30%
                        </td>
                        <td className="px-3.5 pb-2 text-[#D70000] text-sm font-medium text-left">
                          Vanguard
                        </td>
                        <td className="pr-4 pl-3.5 pb-2 text-[#007B1B] text-sm font-medium text-left">
                          ATVI
                        </td>
                      </tr>
                      <tr className="flex  justify-between items-center w-full">
                        <td className="pr-3.5 pl-4 pb-2 text-[#000817] dark:text-white   text-sm font-medium text-left">
                          8.30%
                        </td>
                        <td className="px-3.5 pb-2 text-[#D70000] text-sm font-medium text-left">
                          Vanguard
                        </td>
                        <td className="pr-4 pl-3.5 pb-2 text-[#007B1B] text-sm font-medium text-left">
                          ATVI
                        </td>
                      </tr>
                      <tr className="flex  justify-between items-center w-full">
                        <td className="pr-3.5 pl-4 pb-2 text-[#000817] dark:text-white   text-sm font-medium text-left">
                          8.30%
                        </td>
                        <td className="px-3.5 pb-2 text-[#D70000] text-sm font-medium text-left">
                          Vanguard
                        </td>
                        <td className="pr-4 pl-3.5 pb-2 text-[#007B1B] text-sm font-medium text-left">
                          ATVI
                        </td>
                      </tr>
                      <tr className="flex  justify-between items-center w-full">
                        <td className="pr-3.5 pl-4 pb-2 text-[#000817] dark:text-white   text-sm font-medium text-left">
                          8.30%
                        </td>
                        <td className="px-3.5 pb-2 text-[#D70000] text-sm font-medium text-left">
                          Vanguard
                        </td>
                        <td className="pr-4 pl-3.5 pb-2 text-[#007B1B] text-sm font-medium text-left">
                          ATVI
                        </td>
                      </tr>
                      <tr className="flex  justify-between items-center w-full">
                        <td className="pr-3.5 pl-4 pb-4  text-[#000817] dark:text-white   text-sm font-medium text-left">
                          8.30%
                        </td>
                        <td className="px-3.5 pb-4 text-[#D70000] text-sm font-medium text-left">
                          Vanguard
                        </td>
                        <td className="pr-4 pl-3.5 pb-4 text-[#007B1B] text-sm font-medium text-left">
                          ATVI
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              {/* Financial Summary */}
              <div className=" overflow-x-auto">
                <div className=" min-w-full inline-block align-middle">
                  <div className="border border-[#DCDBEE] rounded-md overflow-hidden">
                    <table className="min-w-full dark:bg-primary-dark bg-white ">
                      <thead>
                        <td className="pl-4 pr-3.5 pb-2 pt-4  text-title  dark:text-white   text-[18px] font-semibold font-normal">
                          Financial Summary
                        </td>
                        <td className="px-3.5 py-2  text-title  dark:text-white   text-base font-normal">
                          Downside
                        </td>
                        <td className="px-3.5 py-2  text-title  dark:text-white   text-base font-normal">
                          Base
                        </td>
                        <td className="pl-3.5 pr-4 py-2  text-title  dark:text-white   text-base font-normal">
                          Upside
                        </td>
                      </thead>
                      <tbody className="">
                        <tr>
                          <td className="pr-3.5 pl-4 py-2  text-[#696868]  dark:text-white   text-base font-normal">
                            Simple Target:
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            $120.00
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            $160.00
                          </td>
                          <td className="pr-4 pl-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            $180.00
                          </td>
                        </tr>
                        <tr>
                          <td className="pr-3.5 pl-4 py-2   text-[#696868] dark:text-white   text-base font-normal">
                            Divident/Other:
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            0.00
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            0.00
                          </td>
                          <td className="pr-4 pl-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            0.00
                          </td>
                        </tr>
                        <tr>
                          <td className="pr-3.5 pl-4 py-2  text-[#696868] dark:text-white   text-base font-normal">
                            Total Return:
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            $120.00
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            $160.00
                          </td>
                          <td className="pr-4 pl-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            $180.00
                          </td>
                        </tr>
                        <tr>
                          <td className="pl-4 pr-3.5 py-2  text-[#696868] dark:text-white   text-base font-normal">
                            Nominal % Return:
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            -10.00%
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            -16.00%
                          </td>
                          <td className="pr-4 pl-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            -180.00%
                          </td>
                        </tr>
                        <tr>
                          <td className="pl-4 pr-3.5 py-2  text-[#696868] dark:text-white   text-base font-normal">
                            Probability:
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left"></td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left"></td>
                          <td className="pr-4 pl-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left"></td>
                        </tr>
                        <tr>
                          <td className="pl-4 pr-3.5 py-2  text-[#696868] dark:text-white font-semibold  text-base font-normal">
                            Risk/Reward:
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white    text-base font-medium text-left">
                            $120.00
                          </td>
                          <td className="px-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            $160.00
                          </td>
                          <td className="pr-4 pl-3.5 py-[20 px]  text-[#000817] dark:text-white   text-base font-medium text-left">
                            $180.00
                          </td>
                        </tr>
                        <tr>
                          <td className="pl-4 pr-3.5 pt-2 pb-4  text-[#696868] dark:text-white font-semibold   text-base font-normal">
                            Annuluzed Return:
                          </td>
                          <td className="px-3.5 pt-2 pb-4  text-[#000817] dark:text-white   text-base font-medium text-left">
                            $120.00
                          </td>
                          <td className="px-3.5 pt-2 pb-4  text-[#000817] dark:text-white   text-base font-medium text-left">
                            $160.00
                          </td>
                          <td className="pr-4 pl-3.5 pt-2 pb-4  text-[#000817] dark:text-white   text-base font-medium text-left">
                            $180.00
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
             
            </div>
          </div>
        </div>
        <div className="min-w-full inline-block align-middle bg-table-light mt-4 dark:bg-table-dark">
          {/* NOTES */}
          <div className="border border-[#DCDBEE] rounded-md overflow-hidden">
            <div className="min-w-full rounded-bl-lg rounded-br-lg">
              <div className="text-left font-semibold  pt-[16px] pb-2 px-[16px]">
                <div className="flex items-center justify-between">
                  <div className="text-xs xs:text-xs text-title sm:text-sm md:text-sm lg:text-lg">
                    Notes
                  </div>
                </div>
              </div>
              <div className="p-[16px]">
                <Table
                  dataFundamental={data.notes}
                  columns={columnsInstance.NotesListDetail()}
                  additionalDataKey="notes"
                  openModal={openModal}
                />
                { }
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FundamentalDetails;
