// React
import React, { useCallback, useState } from "react";
// Third party
import { useNavigate } from "react-router-dom";
// Components
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";
import { Columns } from "../components/Columns/Columns";
import Table from "../components/Tables/MnaFundamentalTable";
// openAPI
import { getApiData } from "../controller/getApiDataConfiguration";
import { expiredTokenValidation } from "../api/expiredTokenValidation";
// Types
import { Sort, HandleMenuProps } from "../interfaces/pages/variedInterfaces";
// Images
import mnaIcon from "../assets/svg/blue-mna.svg";

const MnaList: React.FC<HandleMenuProps> = ({ isMenuOpen, setIsMenuOpen }) => {
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const fetchMnaList = useCallback(
    async (pageIndex: number, pageSize: number, sortBy: Sort[]) => {
      setLoading(true);
      try {
        const api = new getApiData();
        return api.getMnaDomainApi(search, pageIndex, pageSize, sortBy);
      } catch (error) {
        expiredTokenValidation(error);
      } finally {
        setLoading(false);
      }
    },
    [search]
  );

  const columnsInstance = new Columns();

  return (
    <React.Fragment>
      <div
        className={`py-[2rem] pr-[2rem] flex flex-col gap-[29px] ${
          isMenuOpen ? "pl-[316px]" : "pl-[92px]"
        } duration-500`}
      >
        <Breadcrumbs
          breadcrumbs={["MNAs"]}
          icon={mnaIcon}
          addItem={() => navigate("/add-mna")}
          // onFilter={(value) => setSearch(value)}
        />

        <Table
          columns={columnsInstance.MnaList(navigate)}
          fetchData={fetchMnaList}
          search={search}
          loading={loading}
        />
      </div>
    </React.Fragment>
  );
};

export default MnaList;
