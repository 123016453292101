// React
import React, { useEffect, useState } from "react";
// Third party
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
  Column,
} from "react-table";
// API
import { expiredTokenValidation } from "../../api/expiredTokenValidation";
// Images
import Arrow from "../../assets/svg/blue-arrow.svg";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import copyLink from "../../assets/svg/copy_link.svg";
import edit from "../../assets/svg/edit.svg";
import parse from "html-react-parser";

interface TableProps {
  columns: Column[];
  additionalDataKey: string; // Key for the additional data in each row
  openModal?: ((value: any) => void) | undefined;
  caption?: string;
  search?: string;
  loading: boolean;
  fetchData: (pageIndex: number, pageSize: number) => Promise<any>;
}

const Table: React.FC<TableProps> = ({
  columns,
  additionalDataKey,
  openModal,
  fetchData,
  search,
  loading,
}) => {
  const [data, setData] = useState<any[]>([]);
  const [controlledPageCount, setControlledPageCount] = useState(0);
  const [totalElement, setTotalElement] = useState(0);
  const [inputPage, setInputPage] = useState<any>("");
  const navigate = useNavigate();

  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    state,
    canPreviousPage,
    previousPage,
    canNextPage,
    nextPage,
    gotoPage,
    pageCount,
    setPageSize,
    rows,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
      manualSortBy: true,
      manualPagination: true,
      manualGlobalFilter: true,
      pageCount: controlledPageCount,
    } as any,
    useGlobalFilter,
    useSortBy,
    usePagination
  ) as any;

  const { pageIndex, pageSize } = state;

  useEffect(() => {
    gotoPage(0);
    setPageSize(10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  useEffect(() => {
    const fetchDataAndUpdateState = async () => {
      try {
        const result = await fetchData(pageIndex, pageSize);
        setData(result.content);
        setControlledPageCount(Math.ceil(result.page.totalPages));
        setTotalElement(Math.ceil(result.page.totalElements));
      } catch (error) {
        expiredTokenValidation(error);
      }
    };

    fetchDataAndUpdateState();
  }, [fetchData, pageIndex, pageSize, search]);

  // const handleSortToggle = (column: ColumnTable) => {
  //   const { canSort, toggleSortBy, isSortedDesc } = column;

  //   if (canSort) {
  //     toggleSortBy(!isSortedDesc, false);
  //   }
  // };

  return (
    <React.Fragment>
      {/* Table */}
      <table {...getTableProps()} className="min-w-full">
        <tbody {...getTableBodyProps()}>
          <tr>
            <td>
              {rows.map((row: any) => {
                prepareRow(row);
                return (
                  <React.Fragment key={row.id}>
                    <div className="w-full bg-gray-200 dark:bg-gray-900 pb-2.5">
                      <div className="container-fluid mx-auto flex items-start justify-center">
                        <div className="w-full ">
                          <div className="mx-auto w-full bg-[#FFFFFF] dark:bg-gray-800 dark:bg-button-dark border border-[#E0DFE5] rounded-2xl">
                            <div className="bg-[#F9F9FB] justify-between py-3 pl-[10px] pr-[13px] flex flex-col lg:flex-row items-start rounded-t-2xl lg:items-center dark:bg-sub-table-light dark:text-white">
                              <div className="flex flex-col mt-3 lg:mt-0 md:flex-row items-start md:items-center">
                                {row.original.domains?.map(
                                  (domain: any, index: number) => (
                                    <h2
                                      key={index}
                                      className="cursor-pointer text-lg text-primary-dark dark:text-primary-light font-[900]"
                                      onClick={() =>
                                        navigate(
                                          `/${domain.domainName.toLowerCase()}/${
                                            domain.entityId
                                          }`
                                        )
                                      }
                                    >
                                      {domain.name}
                                      {`${
                                        index !==
                                        row.original.domains.length - 1
                                          ? ","
                                          : ""
                                      }`}
                                    </h2>
                                  )
                                )}
                              </div>
                              <div className="flex gap-2 flex-col mt-3 lg:mt-0 md:flex-row  items-start md:items-center">
                                {row.original?.tags?.map(
                                  (tag: any, index: number) => (
                                    <div
                                      key={index}
                                      className="bg-[#DBE9FF] text-primary-dark font-medium dark:text-primary-light dark:bg-primary-dark text-sm leading-3 py-2 px-3 rounded-full"
                                    >
                                      {tag.tag}
                                    </div>
                                  )
                                )}
                                <button className="bg-[#DBE9FF] w-[30px] h-[30px] rounded-full flex items-center justify-center">
                                  <img
                                    className="w-auto"
                                    src={copyLink}
                                    alt="copyLink"
                                  />
                                </button>
                              </div>
                            </div>
                            <div className="justify-between flex flex-col lg:flex-row items-start lg:items-center py-[10px] pl-[10px] pr-[13px]">
                              <div className="w-full lg:w-1/2 pr-0 ">
                                <h1
                                  className="cursor-pointer font-medium text-primary-dark dark:text-primary-light text-base"
                                  onClick={() =>
                                    navigate(`/notes/${row.original.id}`)
                                  }
                                >
                                  {row.original.title}
                                </h1>
                                <p className=" text-base text-[#888888] dark:text-primary-light font-medium">
                                  {row.original.sourceType}
                                </p>
                              </div>
                              <div className="w-full lg:w-1/2">
                                <div className="flex justify-end">
                                  <span className="group relative cursor-pointer">
                                    <div className="absolute bottom-0   translate-x-[-105%] translate-y-[37%] hidden group-hover:block min-w-[330px]">
                                      <div className="bg-[#fff] text-[#888888]  dark:text-white dark:bg-primary-dark  transition-all  text-sm  px-[12px] pt-[12px] pb-[12px] rounded-[10px] border border-[#4185EE] ">
                                        <ul className="max-w-md space-y-1 text-dark list-none list-inside dark:text-white">
                                          <li>
                                            Date Published:{" "}
                                            {moment(
                                              row.original.datePublished
                                            ).format("MM/DD/yyyy hh:mm a")}
                                          </li>
                                          <li>
                                            Date Created:{" "}
                                            {moment(
                                              row.original.dateCreated
                                            ).format("MM/DD/yyyy hh:mm a")}{" "}
                                            {row.original.createdBy?.username}
                                          </li>
                                          <li>
                                            Date Modified:{" "}
                                            {moment(
                                              row.original.dateModified
                                            ).format("MM/DD/yyyy hh:mm a")}{" "}
                                            {
                                              row.original.lastModifiedBy
                                                ?.username
                                            }
                                          </li>
                                        </ul>
                                        <div
                                          className="bg-[#4185EE] -translate-x-1/2 translate-y-1/2 absolute bottom-0 right-[-15px] top-[35%]  w-4 h-4 "
                                          style={
                                            {
                                              clipPath:
                                                "polygon(100% 50%, 50% 0, 50% 100%)",
                                            } as React.CSSProperties
                                          }
                                        ></div>
                                      </div>
                                    </div>
                                    <h2 className=" text-[#888888] dark:text-primary-light font-medium  text-sm leading-6 lg:mt-0 lg:text-end">
                                      {moment(
                                        row.original.datePublished
                                      ).format("MM/DD/yyyy hh:mm a")}
                                    </h2>
                                  </span>
                                </div>
                                {(row.original.sentiment === -1 ||
                                  row.original.sentiment === 0 ||
                                  row.original.sentiment === 1) && (
                                  <h2 className="text-[#888888] dark:text-primary-light font-medium text-sm leading-6 lg:mt-0 lg:text-end">
                                    Sentiment:{" "}
                                    {row.original.sentiment === -1
                                      ? "Negative"
                                      : row.original.sentiment === 0
                                      ? "Neutral"
                                      : "Positive"}
                                  </h2>
                                )}
                              </div>
                            </div>
                            <div className="flex flex-col lg:flex-row ">
                              <div className="flex flex-col lg:flex-row w-full lg:w-11/12 items-start lg:items-center mb-8 lg:mb-0">
                                <div
                                  className=""
                                  style={{ position: "relative" }}
                                >
                                  <div
                                    className="pb-[27px] pl-[10px]"
                                   
                                  >
                                    {row.original.content && parse(row.original.content)}
                                  </div>
                                  <div></div>
                                </div>
                              </div>
                              <div className="flex items-end w-full lg:w-1/12 justify-end mb-[27px] lg:justify-end">
                                <button
                                  className="text-secondary font-semibold text-base capitalize underline float-right"
                                  onClick={() =>
                                    navigate(`/notes/${row.original.id}/edit`)
                                  }
                                >
                                  <div className="rounded-full mr-[13px] p-[8px] border border-none bg-[#FF9E2A] cursor-pointer">
                                    <img
                                      className="h-[14px] w-[14px]"
                                      src={edit}
                                      alt="edit note"
                                    />
                                  </div>
                                </button>
                              </div>
                            </div>
                          </div>
                          {/* Card code block end */}
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </td>
          </tr>
        </tbody>
      </table>
      {/* Pagination */}
      <div className="flex flex-col md:flex-row justify-between mt-6 ">
        <div className="mb-4 md:mb-0 md:flex md:justify-between md:items-center">
          <div className="mb-2 md:mb-0">
            <span className="text-primary text-lg font-medium border-r border-primary pr-5">
              Showing {data.length !== 0 && pageIndex * pageSize + 1}{" "}
              {data.length !== 0 && "-"}{" "}
              {pageIndex === pageCount - 1
                ? Math.min((pageIndex + 1) * pageSize, totalElement)
                : Math.min((pageIndex + 1) * pageSize, totalElement)}{" "}
              of {totalElement}
            </span>
          </div>
          <div className="flex items-center gap-4">
            <div className="text-primary text-sm font-normal md:ml-5">
              Show Entries
            </div>
            <div className="relative">
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                className="dark:text-white dark:placeholder:text-white dark:bg-b-secondary-dark dark:border-white border  border-primary rounded-full px-[22px] mr-4 py-[12px] text-primary   appearance-none outline-none cursor-pointer w-[82px]"
              >
                {[10, 20, 30, 40, 50].map((size) => (
                  <option key={size} value={size}>
                    {size}
                  </option>
                ))}
              </select>
              <span className="absolute top-1/2 right-8  -translate-y-1/2">
                <img src={Arrow} alt="dropdown" className="" />
              </span>
            </div>
          </div>
        </div>
        <div className="text-secondary flex items-center mt-4 md:mt-0">
          <button
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className="mr-5"
          >
            <svg
              className="dark:brightness-[4]"
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
            >
              <path
                d="M9.1233 9.87681L12.1644 6.83576L10.6438 5.31524L6.08228 9.87681L10.6438 14.4385L12.1644 12.9179L9.1233 9.87681Z"
                fill="#F3797E"
              />
            </svg>
          </button>
          {Array.from({ length: pageCount }, (_, i) => {
            const pageNumber = i + 1; // Add 1 to start from 1
            if (pageNumber > 0) {
              if (pageCount > 4) {
                if (
                  (pageNumber >= pageIndex && pageNumber <= pageIndex + 2) ||
                  pageNumber === 1 ||
                  pageNumber === pageCount
                ) {
                  return (
                    <button
                      key={i}
                      onClick={() => gotoPage(pageNumber - 1)} // Subtract 1 when calling gotoPage
                      className={`px-2  text-secondary  dark:text-primary-light ${
                        pageNumber === pageIndex + 1 ? "border-b-2" : ""
                      }`}
                    >
                      <span>{pageNumber}</span>
                    </button>
                  );
                } else if (
                  (pageIndex >= 3 && pageNumber === 2) ||
                  (pageIndex < pageCount - 2 && pageNumber === pageCount - 1)
                ) {
                  // Render ellipsis for 2nd and 2nd-to-last page when not on the edge
                  return (
                    <span
                      key={`ellipsis${i}`}
                      className="p-2 text-secondary dark:text-primary-light"
                    >
                      ...
                    </span>
                  );
                }
              } else {
                // Render pagination for 3 or fewer pages
                return (
                  <button
                    key={i}
                    onClick={() => gotoPage(pageNumber - 1)} // Subtract 1 when calling gotoPage
                    className={`px-2  text-secondary  dark:text-primary-light ${
                      pageNumber === pageIndex + 1 ? "border-b-2" : ""
                    } `}
                  >
                    <span>{pageNumber}</span>
                  </button>
                );
              }
            }
            return null;
          })}

          <div className="flex items-center">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                gotoPage(Number(inputPage) - 1);
                // setInputPage("");
              }}
            >
              <button
                disabled={inputPage > pageCount}
                className="hidden md:inline-block text-secondary dark:text-primary-light"
                type="submit"
              >
                Go{" "}
              </button>
              <input
                type="number"
                className="border border-[#EDEDF7] bg-[#ffffff] rounded-md mx-2 max-w-[50px] outline-none px-2 dark:bg-primary-dark text-secondary dark:text-primary-light"
                value={inputPage}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  setInputPage(inputValue);
                }}
              />
            </form>
          </div>
          <button onClick={() => nextPage()} disabled={!canNextPage}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="19"
              viewBox="0 0 19 19"
              fill="none"
              className="dark:brightness-[4]"
            >
              <path
                d="M9.5537 9.87681L6.51257 6.83576L8.03319 5.31524L12.5947 9.87681L8.03319 14.4385L6.51257 12.9179L9.5537 9.87681Z"
                fill="#F3797E"
              />
            </svg>
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Table;
