// React
import React, { useCallback, useState } from "react";
// Third party
import { useNavigate } from "react-router-dom";
// Components
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";
// Images
import RegulatoryIcon from "../assets/svg/Regulatory.svg";
import { expiredTokenValidation } from "../api/expiredTokenValidation";
import Table from "../components/Tables/MnaFundamentalTable";
import { Columns } from "../components/Columns/Columns";
import { RegulatoryDomainApi } from "../openapi";
import { apiConfig } from "../components/ConfigurationApi/Configuration";
import { Sort } from "../interfaces/pages/variedInterfaces";
// Types
import { HandleMenuProps } from "../interfaces/pages/variedInterfaces";

const RegulatoryList: React.FC<HandleMenuProps> = ({
  isMenuOpen,
  setIsMenuOpen,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");

  const fetchFundamentalList = useCallback(
    async (pageIndex: number, pageSize: number, sortBy: Sort[]) => {
      setLoading(true);
      try {
        const api = new RegulatoryDomainApi(apiConfig());
        const sort: any = sortBy.length
          ? [`${sortBy[0].id},${sortBy[0].desc ? "DESC" : "ASC"}`]
          : "";
        const response = await api.getRegulatory(pageIndex, pageSize, sort);
        return response.data;
      } catch (error) {
        expiredTokenValidation(error);
      } finally {
        setLoading(false);
      }
    },
    [search]
  );

  const columnsInstance = new Columns();

  return (
    <React.Fragment>
      <div
        className={`py-[30px] pr-[24px] ${
          isMenuOpen ? "pl-[314px]" : "pl-[84px]"
        } duration-500`}
      >
        <Breadcrumbs
          breadcrumbs={["Regulatory"]}
          icon={RegulatoryIcon}
          addItem={() => navigate("/regulatory/add")}
        />
        <div className="mt-[31px]">
          <Table
            columns={columnsInstance.RegulatoryList(navigate)}
            fetchData={fetchFundamentalList}
            loading={loading}
          />
        </div>{" "}
      </div>
    </React.Fragment>
  );
};

export default RegulatoryList;
