import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";
import AsyncSelect from "../components/AsyncSelect/indexForMiniForm";
import { apiConfig } from "../components/ConfigurationApi/Configuration";
import {
  FundamentalDomainApi,
  MnaCategoryDomainApi,
  MnaViewApi,
  SectorDomainApi,
} from "../openapi";
import { HandleMenuProps } from "../interfaces/pages/variedInterfaces";
import { expiredTokenValidation } from "../api/expiredTokenValidation";
import ReactDatePicker from "react-datepicker";
import moment from "moment";

const initialValues = {
  fundamentalSearchTarget: { value: "", label: "" },
  fundamentalSearchAcquire: { value: "", label: "" },
  tickerTarget: "",
  tickerAcquire: "",
  nameTarget: "",
  nameAcquire: "",
  sectorTarget: "",
  sectorAcquire: "",
  headquatersTarget: "",
  headquatersAcquire: "",
  categoryId: undefined,
  dateAnnounced: "",
};

const validationSchema = Yup.object({
  categoryId: Yup.string()
    .required("Category Id is required")
    .notOneOf([""], "Category must be selected"),
  dateAnnounced: Yup.string()
    .required("Date Announced is required")
    .matches(/^\d{2}\/\d{2}\/\d{4}$/, "Date must be in MM/DD/YYYY format"),
  nameTarget: Yup.string()
    .max(50, "Target name must be at most 50 characters")
    .required("Target name is required"),
  nameAcquire: Yup.string()
    .max(50, "Acquirer name must be at most 50 characters")
    .required("Acquirer name is required"),
});

interface FundamentalContent {
  id: number;
  ticker?: string;
  name: string;
  headquarters: string;
}

export default function AddEditMnaMiniForm({
  isMenuOpen,
  setIsMenuOpen,
}: HandleMenuProps) {
  const [loading, setLoading] = useState<boolean>(false);
  const [sectorList, setSectorList] = useState<any>([]);
  const [categoryList, setCategoryList] = useState<any>([]);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const navigate = useNavigate();

  const navigateDashboard = () => {
    navigate("/mnas");
  };

  const fetchSectorlist = async () => {
    try {
      const api = new SectorDomainApi(apiConfig());
      const response = await api.getSectors();
      const filteredOptions = response?.data?.content!.map((item: any) => ({
        value: item.id,
        label: item.name,
      }));
      setSectorList(filteredOptions);
    } catch (error) {
      expiredTokenValidation(error);
    }
  };

  const fetchCategorylist = async () => {
    try {
      const api = new MnaCategoryDomainApi(apiConfig());
      const response = await api.getMnaCategories("", 0, 20, undefined);
      const filteredOptions = response?.data?.content!.map((item: any) => ({
        value: item.id,
        label: item.name,
      }));
      setCategoryList(filteredOptions);
    } catch (error) {
      expiredTokenValidation(error);
    }
  };

  useEffect(() => {
    fetchSectorlist();
    fetchCategorylist();
  }, []);

  const api = new FundamentalDomainApi(apiConfig());
  const pageable = { page: 0, size: 20, sort: ["asc"] };

  async function fetchFundamentalByQuery(
    value: any
  ): Promise<{ value: number; label: string }[]> {
    setLoading(true);
    try {
      const fundamentalsResponse = await api.searchFundamentalByNameOrTicker(
        value,
        pageable
      );
      const fundamentalsContent = (
        fundamentalsResponse.data.content as FundamentalContent[]
      )?.map((content) => ({
        value: content.id || 0,
        label: `${content.ticker ? content.ticker + "-" : ""} ${content.name}`,
      }));
      return fundamentalsContent || [{ value: 0, label: "" }];
    } catch (error) {
      console.error("Error fetching fundamentals:", error);
      return [{ value: 0, label: "Error loading data" }];
    } finally {
      setLoading(false);
    }
  }

  async function getFundamentalById(id: string) {
    const fundamentalResponse = await api.getFundamentalById(id);
    return fundamentalResponse.data;
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { setStatus, resetForm }) => {
      const mnaToCreate = {
        categoryId: Number(values.categoryId),
        dateAnnounced: moment(values.dateAnnounced, "MM/DD/YYYY").format(
          "YYYY-MM-DD"
        ),
        target: {
          id: values.fundamentalSearchTarget.value || "",
          name: values.nameTarget || "",
          ticker: values.tickerTarget || "",
          sectorId: Number(values.sectorTarget),
          headquarters: values.headquatersTarget || "",
        },
        acquirer: {
          id: values.fundamentalSearchAcquire.value || "",
          name: values.nameAcquire || "",
          ticker: values.tickerAcquire || "",
          sectorId: Number(values.sectorAcquire),
          headquarters: values.headquatersAcquire || "",
        },
      };
      try {
        setLoading(true);
        const api = new MnaViewApi(apiConfig());

        await api.createViewMna(mnaToCreate);
        setStatus({ success: "MNA created successfully!" });
        resetForm();
        navigateDashboard();
        setLoading(false);
      } catch (error) {
        console.error("Error creating MNA:", error);
        setStatus({ error: "Failed to create MNA. Please try again." });
      }
    },
  });

  const handleDateChange = (date: Date | null) => {
    const formattedDate = date ? moment(date).format("MM/DD/yyyy") : "";
    formik.setFieldValue("dateAnnounced", formattedDate);
    setSelectedDate(date);
  };

  useEffect(() => {
    const updateTargetFields = async () => {
      if (formik.values.fundamentalSearchTarget?.value) {
        try {
          const fundamentalTarget = await getFundamentalById(
            formik.values.fundamentalSearchTarget.value
          );
          formik.setFieldValue("tickerTarget", fundamentalTarget.ticker);
          formik.setFieldValue("nameTarget", fundamentalTarget.name);
          formik.setFieldValue("sectorTarget", fundamentalTarget.sectorId);
          formik.setFieldValue("headquatersTarget", fundamentalTarget.hq);
        } catch (error) {
          console.error("Error fetching fundamental target:", error);
        }
      } else {
        formik.setFieldValue("tickerTarget", "");
        formik.setFieldValue("nameTarget", "");
        formik.setFieldValue("sectorTarget", "");
        formik.setFieldValue("headquatersTarget", "");
      }
    };
    updateTargetFields();
  }, [formik.values.fundamentalSearchTarget?.value]);

  useEffect(() => {
    const updateAcquireFields = async () => {
      if (formik.values.fundamentalSearchAcquire?.value) {
        try {
          const fundamentalAcquirer = await getFundamentalById(
            formik.values.fundamentalSearchAcquire.value
          );
          formik.setFieldValue("tickerAcquire", fundamentalAcquirer.ticker);
          formik.setFieldValue("nameAcquire", fundamentalAcquirer.name);
          formik.setFieldValue("sectorAcquire", fundamentalAcquirer.sectorId);
          formik.setFieldValue("headquatersAcquire", fundamentalAcquirer.hq);
        } catch (error) {
          console.error("Error fetching fundamental acquirer:", error);
        }
      } else {
        formik.setFieldValue("tickerAcquire", "");
        formik.setFieldValue("nameAcquire", "");
        formik.setFieldValue("sectorAcquire", "");
        formik.setFieldValue("headquatersAcquire", "");
      }
    };
    updateAcquireFields();
  }, [formik.values.fundamentalSearchAcquire?.value]);

  return (
    <React.Fragment>
      <form
        onSubmit={formik.handleSubmit}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
      >
        <div
          className={`py-[25px] pr-[25px] pb-[60px] duration-500 h-full ${
            isMenuOpen ? "pl-[310px]" : "pl-[85px]"
          } `}
        >
          <Breadcrumbs breadcrumbs={["Create MNA"]} />
          <div className="bg-[#fff] mt-2.5 border border-[#EEEEEE] rounded-md h-full p-4 pl-10">
            <div className="grid grid-cols-[2fr_3fr_3fr] gap-4">
              <div className="p-2 h-12"></div>
              <div className=" py-2 font-semibold text-base h-12">Target</div>
              <div className=" py-2 font-semibold text-base h-12">Acquire</div>
              <div className=" py-2 font-semibold text-base h-12">
                Fundamental Search
              </div>
              <div className="border border-[#eeeeee] rounded-md h-12">
                <AsyncSelect
                  id={"fundamentalSearchTarget"}
                  name={"fundamentalSearchTarget"}
                  placeholder="Search Fundamentals..."
                  data-testid="target"
                  fetchOptions={fetchFundamentalByQuery}
                  value={formik.values.fundamentalSearchTarget}
                  formik={formik}
                  loading={loading}
                  error={""}
                  noOptionsMessage="No company found"
                  onkeydown={(e) => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                      fetchFundamentalByQuery(e?.target?.value);
                    } else {
                      formik.setValues({
                        ...formik.values,
                        fundamentalSearchTarget: e.target.value,
                      });
                    }
                  }}
                />
              </div>
              <div className="border border-[#eeeeee] rounded-md h-12">
                <AsyncSelect
                  id={"fundamentalSearchAcquire"}
                  name={"fundamentalSearchAcquire"}
                  placeholder="Search Fundamentals..."
                  data-testid="acquirer"
                  fetchOptions={fetchFundamentalByQuery}
                  value={formik.values.fundamentalSearchAcquire}
                  formik={formik}
                  loading={loading}
                  error={""}
                  noOptionsMessage="No company found"
                  onkeydown={(e) => {
                    if (e.keyCode === 13) {
                      e.preventDefault();
                      fetchFundamentalByQuery(e?.target?.value);
                    } else {
                      formik.setValues({
                        ...formik.values,
                        fundamentalSearchAcquire: e.target.value,
                      });
                    }
                  }}
                />
              </div>
              <div className=" py-2 font-semibold text-base">Ticker</div>
              <div className="border border-[#eeeeee] rounded-md overflow-hidden h-12">
                <input
                  id={"tickerTarget"}
                  type="text"
                  name={"tickerTarget"}
                  value={formik.values.tickerTarget}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`dark:text-white dark:placeholder:text-white  dark:bg-primary-dark px-[15px] text-base text-[#7B7B7B] font-light w-full h-12  outline-none`}
                  autoComplete="off"
                />
              </div>
              <div className="border border-[#eeeeee] rounded-md overflow-hidden h-12">
                <input
                  id={"tickerAcquire"}
                  type="text"
                  name={"tickerAcquire"}
                  value={formik.values.tickerAcquire}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`dark:text-white dark:placeholder:text-white  dark:bg-primary-dark px-[15px] text-base text-[#7B7B7B] font-light w-full h-12  outline-none`}
                  autoComplete="off"
                />
              </div>
              <div className=" py-2 font-semibold text-base">Name</div>
              <div
                id="nameTargetContainer"
                className={`border border-[#eeeeee] rounded-md overflow-hidden h-12 ${
                  formik.errors.nameTarget &&
                  formik.touched.fundamentalSearchTarget?.value &&
                  "border border-[#ff6464]"
                }`}
              >
                <input
                  id={"nameTarget"}
                  type="text"
                  name={"nameTarget"}
                  value={formik.values.nameTarget}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`dark:text-white dark:placeholder:text-white  dark:bg-primary-dark px-[15px] text-base text-[#7B7B7B] font-light w-full h-12  outline-none`}
                  autoComplete="off"
                />
              </div>
              <div
                id="nameAcquireContainer"
                className={`border border-[#eeeeee] rounded-md overflow-hidden h-12 ${
                  formik.errors.nameAcquire &&
                  formik.touched.fundamentalSearchTarget?.value &&
                  "border border-[#ff6464]"
                }`}
              >
                <input
                  id={"nameAcquire"}
                  type="text"
                  name={"nameAcquire"}
                  value={formik.values.nameAcquire}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`dark:text-white dark:placeholder:text-white  dark:bg-primary-dark px-[15px] text-base text-[#7B7B7B] font-light w-full h-12  outline-none`}
                  autoComplete="off"
                />
              </div>
              <div className=" py-2 font-semibold text-base">Sector</div>
              <div className="border border-[#eeeeee] rounded-md overflow-hidden h-12">
                <div className="relative w-full pr-[15px]">
                  <select
                    id="sectorTarget"
                    name="sectorTarget"
                    className={`dark:text-white dark:placeholder:text-white  dark:bg-primary-dark px-[15px] text-base text-[#7B7B7B] font-light w-full h-12  outline-none`}
                    value={formik.values.sectorTarget}
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                  >
                    <option value="">Sector</option>

                    {sectorList.map((item: any, index: number) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="border border-[#eeeeee] rounded-md overflow-hidden h-12">
                <div className="relative w-full pr-[15px]">
                  <select
                    id="sectorAcquire"
                    name="sectorAcquire"
                    className={`dark:text-white dark:placeholder:text-white  dark:bg-primary-dark px-[15px] text-base text-[#7B7B7B] font-light w-full h-12  outline-none`}
                    value={formik.values.sectorAcquire}
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                  >
                    <option value="">Sector</option>

                    {sectorList.map((item: any, index: number) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className=" py-2 font-semibold text-base">Headquarters</div>
              <div className="border border-[#eeeeee] rounded-md overflow-hidden h-12">
                <input
                  id={"headquatersTarget"}
                  type="text"
                  name={"headquatersTarget"}
                  value={formik.values.headquatersTarget}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`dark:text-white dark:placeholder:text-white  dark:bg-primary-dark px-[15px] text-base text-[#7B7B7B] font-light w-full h-12  outline-none`}
                  autoComplete="off"
                />
              </div>

              <div className="border border-[#eeeeee] rounded-md overflow-hidden h-12">
                <input
                  id={"headquatersAcquire"}
                  type="text"
                  name={"headquatersAcquire"}
                  value={formik.values.headquatersAcquire}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={`dark:text-white dark:placeholder:text-white  dark:bg-primary-dark px-[15px] text-base text-[#7B7B7B] font-light w-full h-12  outline-none`}
                  autoComplete="off"
                />
              </div>
            </div>
            <div className="grid grid-cols-[3fr_3fr] gap-x-4 mt-[36px]" >
              <div className=" pb-2 font-semibold text-base">Category</div>
              <div className=" pb-2 font-semibold text-base ">
                Date announced
              </div>

              <div
                className={`border border-[#eeeeee] rounded-md overflow-hidden h-12 ${
                  formik.errors.categoryId && formik.touched.categoryId
                    ? "border border-[#ff6464]"
                    : ""
                }`}
              >
                <div className="relative w-full pr-[15px]">
                  <select
                    id="categoryId"
                    name="categoryId"
                    className={`dark:text-white dark:placeholder:text-white  dark:bg-primary-dark px-[15px] text-base text-[#7B7B7B] font-light w-full h-12  outline-none`}
                    value={formik.values.categoryId}
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                  >
                    <option value="">Category</option>

                    {categoryList.map((item: any, index: number) => (
                      <option key={index} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className=" pb-2 font-semibold text-base h-12">
                <ReactDatePicker
                  selected={selectedDate}
                  onChange={handleDateChange}
                  onBlur={formik.handleBlur}
                  name="dateAnnounced"
                  className={`dark:text-white dark:bg-primary-dark border border-[#eeeeee] rounded-md text-left px-4 text-base text-[#7B7B7B] h-12 font-light w-full outline-none ${
                    formik.errors.dateAnnounced && formik.touched.dateAnnounced
                      ? "border border-[#ff6464]"
                      : ""
                  }`}
                  dateFormat="MM/dd/yyyy"
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </div>
              <div className="mt-[35px] gap-2.5 flex h-12"></div>
              <div className="mt-[35px] gap-2.5 flex h-12 justify-end">
                <button
                  onClick={navigateDashboard}
                  className="bg-[#5EC1F92E]  rounded-md h-12 flex items-center justify-center w-1/3 font-extrabold text-sm"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-[#3B97FF] text-[#fff] rounded-md h-12 flex items-center justify-center w-1/3 font-extrabold text-sm"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </React.Fragment>
  );
}
